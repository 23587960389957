import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeDateTime } from 'src/app/employee/models/EmployeeDateTime';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { CompanyEmployeePolicy } from 'src/app/policy/models/CompanyEmployeePolicy';
import { PolicyService } from 'src/app/policy/services/policy.service';
import { EmployeeDatetimeType } from 'src/app/setting/models/EmployeeDatetimeType';
import { DatetimesTypesStoreService } from 'src/app/setting/services/datetimes-types-store.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { FormatHelper } from 'src/app/shared/common/FormatHelper';
import { SummernoteHelper } from 'src/app/shared/common/SummernoteHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { DateTimeProject } from '../../../../setting/models/DatetimeProject';
import { DatetimeProjectService } from '../../../../setting/services/datetime-project.service';
import { AttendanceApprovers } from '../../../models/AttendanceApprovers';
import { EmployeeService } from '../../../services/employee.service';

@Component({
    selector: 'app-employee-attendance-info',
    templateUrl: './create-edit-employee-datetime-modal.component.html',
    styleUrls: ['./create-edit-employee-datetime-modal.component.css']
})
export class CreateEditEmployeeDatetimeModalComponent implements OnInit, OnDestroy {
    public employeeDateTime: EmployeeDateTime;
    public employeeID: number;
    public dateTimeEditForm: UntypedFormGroup;
    public datetimeTypes$: Observable<EmployeeDatetimeType[]>;
    public employeeDatetimeInfo$: Observable<{
        drawn_units: string,
        remaining_units: string,
        holidays_count: string,
        weekend_days_count: string,
        overflow_alert: boolean,
        has_waiting_units?: boolean,
        waiting_units?: string,
    }>;
    public enableTime = false;
    public end: string;
    public formValueChangeSubscription: Subscription;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public minDate = '2020-01-01';
    public permSub: Subscription;
    public selectedType: string;
    public shiftValueChangeSubscription: Subscription;
    public start: string;
    public submitted = false;
    public disableType = false;
    public isTimer = false;
    public isShift = false;
    public isEmployeeProfile = false;
    public isPlan = false;
    public isApprovable = true;
    public approvers$: Observable<AttendanceApprovers>;
    public datetimeProjects$: Observable<DateTimeProject[]>;
    public projectsEnabled = this._authService.loggedUser.attendance_projects.active;
    public editEnabled = true;
    public isLoading = false;

    public constructor(
        public fpHelper: FlatpickrHelper,
        public activeModal: NgbActiveModal,
        public summernoteHelper: SummernoteHelper,
        private _attendanceService: AttendanceService,
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _datetimeTypesStore: DatetimesTypesStoreService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _formatHelper: FormatHelper,
        private _policyService: PolicyService,
        private _datetimeProjectService: DatetimeProjectService
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.dateTimeEditForm.controls;
    }

    private _employee: Employee;

    public get employee(): Employee {
        return this._employee;
    }

    public set employee(employee: Employee) {
        this._employee = employee;
        this.employeeID = this._employee.employee_ID;
    }

    public ngOnDestroy(): void {
        this.shiftValueChangeSubscription?.unsubscribe();
        this.formValueChangeSubscription?.unsubscribe();
        this.permSub?.unsubscribe();
    }

    // set on shift je znegovan z důvodu změny hlášky na "chci nastavit i čas" = jak jsme se bavili - Honza bude vedet

    public ngOnInit(): void {
        console.log(this.isLoading);
        if (!this.isLoading) {
            this.init();
        }
    }

    public init(): void {
        this.dateTimeEditForm = this._fb.group({
            employee_datetime_type_ID: [1, Validators.required],
            from: ['', Validators.required],
            to: ['', Validators.required],
            set_on: ['SHIFT', Validators.required],
            note: [''],
            report: [''],
            state: ['NEW', Validators.required],
        });

        if (this.projectsEnabled) {
            this.dateTimeEditForm.addControl('employee_datetime_project_ID', this._fb.control(null));
        }

        if (this.employeeDateTime) {
            this.dateTimeEditForm.patchValue({
                employee_datetime_type_ID: this.employeeDateTime.type.employee_datetime_type_ID,
                from: this.employeeDateTime.from,
                to: this.employeeDateTime.to,
                note: this.employeeDateTime.note,
                report: this.employeeDateTime.report,
                state: this.employeeDateTime.state,
                set_on: this.employeeDateTime.set_on,
            });

            if (this.employeeDateTime.is_protected && this._authService.loggedUser.role_level !== 1) {
                this.dateTimeEditForm.disable();
                this.editEnabled = false;
            }

            if (this.projectsEnabled) {
                this.dateTimeEditForm.patchValue({employee_datetime_project_ID: this.employeeDateTime.employee_datetime_project_ID});
            }

            this.enableTime = this.employeeDateTime.set_on === 'TIME';
            this.disableType = true;

            if (!this.employeeDateTime?.permissions?.can_set_state) {
                this.f.state.disable();
            }

            if (this.employeeDateTime?.permissions?.can_set_state) {
                this.f.state.enable();
            }

            this.permSub = this._authService.hasPermissionTo(['employeeDatetime.edit', 'employeeDatetime.editStructure'])
                .subscribe(perm => {
                    if (perm.can) {
                        this.dateTimeEditForm.enable();
                    }
                });
        }

        if (this.employeeID && !this.employeeDateTime) {
            this._policyService.getPolicyByEmployeeID(this.employeeID)
                .pipe(
                    map(policies => {
                        if (policies.length) {
                            return policies[0];
                        } else {
                            return {} as CompanyEmployeePolicy;
                        }
                    })
                )
                .subscribe(policy => {
                    if (!this._formatHelper.objectIsEmpty(policy)) {
                        this.dateTimeEditForm.patchValue({
                            include_weekends: policy.weekend_work === 0,
                            include_holidays: policy.holiday_work === 0
                        });
                    }
                });

            if (this.start && this.end) {
                this.dateTimeEditForm.patchValue({
                    from: this.start,
                    to: this.end,
                });
            }
        }

        this.shiftValueChangeSubscription = this.dateTimeEditForm.get('set_on').valueChanges
            .subscribe(val => {
                this.enableTime = val === 'TIME';
            });

        this.formValueChangeSubscription = this.dateTimeEditForm.valueChanges
            .subscribe(val => {
                if (val.from && val.to) {
                    this.minDate = val.from;

                    this.getEmployeeDatetimeInfoBeforeSave(val);
                } else if (val.from && !val.to) {
                    this.dateTimeEditForm.patchValue({
                        to: val.from
                    });
                }
            });

        this.datetimeTypes$ = this._datetimeTypesStore.datetimeTypes$
            .pipe(
                map(types => {
                    types = types.filter(t => t.employee_datetime_type_ID !== 7 && t.is_shift === this.isShift);
                    let selectedType = types.find(t => t.employee_datetime_type_ID === 5);

                    if (!this.employeeDateTime && !this.isTimer && !this.isEmployeeProfile && !this.isShift && this.isPlan) {
                        types = types.filter(t => t.can_plan);
                    }

                    if (this.isEmployeeProfile && !this.employeeDateTime) {
                        selectedType = types.find(t => t.employee_datetime_type_ID === 1);
                    }

                    if (this.employeeDateTime && this.employeeDateTime.type.employee_datetime_type_ID !== 7) {
                        selectedType = types.find(t => t.employee_datetime_type_ID === this.employeeDateTime?.type?.employee_datetime_type_ID);
                    }

                    if (this.isTimer) {
                        types = types.filter(t => t.timer);
                        selectedType = types.find(t => t.employee_datetime_type_ID === 1);
                    }

                    if (this.isShift) {
                        selectedType = types.find(t => t.is_shift === this.isShift);
                    }

                    this.dateTimeEditForm.patchValue({employee_datetime_type_ID: selectedType?.employee_datetime_type_ID});

                    if (selectedType) {
                        this.getSelectedType(selectedType);
                    }

                    return types;
                }));

        this.approvers$ = this._employeeService.getAttendaceApproversByEmployee(this.employeeID);
        this.datetimeProjects$ = this._datetimeProjectService.getDatetimeProjectsForSelect();
    }

    public deleteDateTime(): void {
        this._attendanceService.deleteEmployeeDateTimeByID(this.employeeID, this.employeeDateTime.employee_datetime_ID)
            .then(() => this.activeModal.close('cancel'))
            .catch(() => { });
    }

    public getEmployeeDatetimeInfoBeforeSave(datetime: EmployeeDateTime): void {
        const modifiedForm = {
            ...this.dateTimeEditForm.value,
            set_on: this.dateTimeEditForm.value.set_on,
        };
        this.employeeDatetimeInfo$ = this._attendanceService.getEmployeeDateTimeInfoBeforeSave(this.employeeID, modifiedForm).pipe(shareReplay());
    }

    public getSelectedType(type: EmployeeDatetimeType): void {
        this.selectedType = type.name;
        this.isApprovable = type.is_approvable;
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.dateTimeEditForm.valid) {
            return;
        }

        const value = this.dateTimeEditForm.value;

        if (value.set_on !== 'TIME') {
            value.from = value.from.substring(0, 10);
            value.to = value.to.substring(0, 10);
        }

        let promise = null;

        if (Object.keys(value).length === 1) {
            promise = this._attendanceService.saveEmployeeDateTimeState(value, this.employeeID);
        } else if (this.employeeDateTime) {
            promise = this._attendanceService.saveEmployeeDateTime(value, this.employeeID, this.employeeDateTime.employee_datetime_ID);
        } else {
            promise = this._attendanceService.saveEmployeeDateTime(value, this.employeeID);
        }

        promise.then(() => this.activeModal.close());
    }
}
