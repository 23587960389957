import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, timer } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root',
})
export class PwaService implements OnDestroy {
    private _newVersionDetected = false;
    private _lastRouteUrl: string;
    private _swUpdateSubscription: Subscription;

    public constructor(
        private _swUpdate: SwUpdate,
        private _router: Router,
        private _appService: AppService
    ) { }

    public ngOnDestroy(): void {
        this._swUpdateSubscription?.unsubscribe();
    }

    public start(): void {
        // fetch backend version every 3 minutes
        this._swUpdateSubscription = timer(0, 180000)
            .pipe(
                switchMap(() => this._appService.getAppVersion())
            )
            .subscribe(async version => {
                if (!this._appService.appVersion) {
                    this._appService.appVersion = version;
                }
                if (version && version !== this._appService.appVersion) {
                    console.log('NEW BACKEND VERSION DETECTED');
                    this._newVersionDetected = true;
                }
            });

        if (this._swUpdate.isEnabled) {
            // fetch frontend version
            this._swUpdateSubscription.add(this._swUpdate.versionUpdates.subscribe(async event => {
                if (event.type === 'VERSION_DETECTED') {
                    console.log('NEW FRONTEND VERSION DETECTED');
                    this._newVersionDetected = true;
                }
            }));
        }

        // reload app when new version is available and route changes
        this._swUpdateSubscription.add(
            this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
                if (!this._lastRouteUrl) {
                    this._lastRouteUrl = event.url;
                }
                if (this._newVersionDetected && event.url !== this._lastRouteUrl) {
                    this._appService.reloadAppWithNewVersion();
                }
                this._lastRouteUrl = event.url;
            }));
    }
}
