import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

interface RouteDefinition {
    name: string;
    routerLink: string;
}

@Component({
    selector: 'app-employee-list-by-type',
    templateUrl: './employee-list-by-type.component.html',
    styleUrls: ['./employee-list-by-type.component.css']
})
export class EmployeeListByTypeComponent {
    public pageTitle$: Observable<string>;

    public routes$: Observable<Array<RouteDefinition>>;

    private _canViewArchived$: Observable<boolean>;

    private _canViewContractorAndPotential$: Observable<boolean>;

    public constructor(
        private _authService: AuthenticationService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _translateService: TranslateService
    ) {
        this._setup();
    }

    public ngOnInit(): void {
        this.pageTitle$ = merge(
            this._router.events.pipe(filter(e => e instanceof NavigationEnd)),
            this._route.firstChild.data
        )
            .pipe(
                switchMap(() => merge(
                    this._route.data.pipe(map(data => data.title)),
                    this._route.firstChild.data.pipe(map(data => data.title)),
                    this._authService.pageTitle
                )),
                filter(title => title !== null)
            );
    }

    private _setup(): void {
        const canView$ = this._authService.hasPermissionTo('employee.view')
            .pipe(
                map(permission => permission.can),
                shareReplay()
            );

        const canViewStructure$ = this._authService.hasPermissionTo('employee.viewStructure')
            .pipe(
                map(permission => permission.can),
                shareReplay()
            );

        this._canViewArchived$ = canView$ || canViewStructure$;

        this._canViewContractorAndPotential$ = combineLatest([
            canView$,
            canViewStructure$,
        ])
            .pipe(
                map(([canView, canViewStructure]) => canView || canViewStructure),
                shareReplay()
            );

        this.routes$ = combineLatest([
            this._canViewArchived$,
            this._canViewContractorAndPotential$,
        ])
            .pipe(
                map(([canViewArchived, canContractorAndPotential]) => {
                    const routes: Array<RouteDefinition | null> = [];

                    if (canContractorAndPotential) {
                        routes.push(
                            {
                                name: this._translateService.instant('employees.page_title_index_contractor'),
                                routerLink: 'contractor'
                            },
                            {
                                name: this._translateService.instant('employees.page_title_index_potential'),
                                routerLink: 'potential'
                            },
                        );
                    }

                    if (canViewArchived) {
                        routes.push({
                            name: this._translateService.instant('employees.page_title_index_archived'),
                            routerLink: 'archive'
                        });
                    }

                    return routes;
                })
            );
    }
}
