import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { File } from 'src/app/employee/models/File';
import { DocumentService } from 'src/app/employee/services/document.service';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { Property } from 'src/app/property/models/Property';
import { PropertyService } from 'src/app/property/services/property.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { MultiAssignUnassignPropertyComponent } from '../../../../property/components/sections/multi-assign-unassign-property/multi-assign-unassign-property.component';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { UserService } from '../../../../setting/services/user.service';
import { MultiFilesPreviewModalComponent } from '../../../../shared/components/partials/multi-files-preview-modal/multi-files-preview-modal.component';
import { AvailableFeatures } from '../../../models/AvailableFeatures';

@Component({
    selector: 'app-employee-properties',
    templateUrl: './employee-properties.component.html',
    styleUrls: ['./employee-properties.component.css']
})
export class EmployeePropertiesComponent extends FatherOfListComponent<Property> implements OnInit, OnDestroy {
    public employee$: Observable<Employee>;
    public employeeID: number;
    public itemsPerPage = 99999999999;
    public canEdit = false;
    public selectedPropertyFiles$: Observable<Array<File>>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public availableFeatures$: Observable<AvailableFeatures>;
    public checkedWholePage = false;
    public checkedEmployeePropertyIds: number[] = [];
    public permissions: Array<string>;
    public watchingMyself = false;
    public sort: Sort<Property> = {
        column: 'name',
        direction: SORT_ASC
    };

    private _employeeID$: Observable<number>;
    private _fetchProperties$ = new ReplaySubject<void>(1);
    private _subscription: Subscription;

    public constructor(
        public documentService: DocumentService,
        private _employeeService: EmployeeService,
        private _companyService: CompanyService,
        private _modalService: NgbModal,
        private _propertyService: PropertyService,
        private _route: ActivatedRoute,
        private _cdr: ChangeDetectorRef,
        private _subscriptionService: SubscriptionService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
        super.ngOnDestroy();
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;

        this.availableFeatures$ = this._subscriptionService.availableFeatures$;

        this.permissions = this._authService?.permissions?.map(perm => perm.name);

        this._subscription = this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);

            const loggedEmployeeID = this._authService?.employee?.employee_ID;
            this.watchingMyself = loggedEmployeeID === this.employeeID;
            this._cdr.detectChanges();

            this.employee$ = this._employeeService.getEmployeeInfoByEmployeeID(this.employeeID, 'extend')
                .pipe(shareReplay());

            this._rows$ = this._fetchProperties$
                .pipe(
                    tap(() => this._loading$.next(true)),
                    map(() => this._buildParams()),
                    switchMap(listParams => this._propertyService.getAllPropertiesByEmployeeID(listParams, this.employeeID)),
                    map(response => this._setupList(response)),
                    catchError(() => of([])),
                    tap(() => this._loading$.next(false)),
                    shareReplay()
                );

            this._fetchListData();
        });

        this._init();

        this.canEdit = this._authService.checkPermission('property.edit');
    }

    public generateTransferProtocol(property: Property): void {
        this._propertyService.generateTransferProtocolByPropertyAndEmployeeID(property.property_ID, this.employeeID)
            .then(() => this._fetchListData());
    }

    public openPropertyFilesModal(property: Property): void {
        const modalRef = this._modalService.open(MultiFilesPreviewModalComponent, {centered: true, size: 'xl'});
        modalRef.componentInstance.files$ = of(property.files);
    }

    public searchChanged(search: string): void {
        this.search = search;
    }

    public sortChanged(sort: Sort<Property>): void {
        this.sort = sort;
    }

    public trackByFn(index: number, property: Property): number {
        return property.property_ID;
    }

    public checkPermission(name: string): boolean {
        return (this.watchingMyself || this.permissions.includes(name + '.view') || this.permissions.includes(name + '.viewStructure'));
    }

    public checkUncheckAll(e: any) {
        if (e.target.checked) {
            this.rows.forEach(row => {
                if (!this.checkedEmployeePropertyIds.includes(row.property_ID)) {
                    this.checkedEmployeePropertyIds.push(row.property_ID);
                }
            });
        } else {
            this.rows.forEach(row => {
                if (this.checkedEmployeePropertyIds.includes(row.property_ID)) {
                    this.checkedEmployeePropertyIds.splice(this.checkedEmployeePropertyIds.indexOf(row.property_ID), 1);
                }
            });
        }
    }

    public rowCheckBoxChecked(e: any, propertyID: number) {
        const isChecked = e.target.checked;
        if (isChecked) {
            this.checkedEmployeePropertyIds.push(propertyID);
        } else {
            this.checkedEmployeePropertyIds.splice(this.checkedEmployeePropertyIds.indexOf(propertyID), 1);
        }
        this.checkIfPageIsSelectedAll();
    }

    public checkIfPageIsSelectedAll(rows = this.rows) {
        for (const row of rows) {
            if (!this.checkedEmployeePropertyIds.includes(row.property_ID)) {
                this.checkedWholePage = false;
                return;
            }
        }
        this.checkedWholePage = true;
    }

    public openMultiUnassignModal(): void {
        const modalRef = this._modalService.open(MultiAssignUnassignPropertyComponent, {centered: true});

        modalRef.componentInstance.propertyIDs = this.checkedEmployeePropertyIds;
        modalRef.componentInstance.action = 'unassign';
        modalRef.componentInstance.employeeID = this.employeeID;

        modalRef.result.then(
            () => this._fetchListData(),
            () => { }
        );
    }

    protected _fetchListData(): void {
        this._fetchProperties$.next();
        this.checkedEmployeePropertyIds = [];
        this.checkedWholePage = false;
    }
}
