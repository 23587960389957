import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EmployeeDateTime } from 'src/app/employee/models/EmployeeDateTime';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { FatherOfListComponent, Sort, SORT_DESC } from 'src/app/ui';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { EmployeeDatetimeType } from '../../../../setting/models/EmployeeDatetimeType';
import { DatetimesTypesStoreService } from '../../../../setting/services/datetimes-types-store.service';
import { UserService } from '../../../../setting/services/user.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { Employee } from '../../../models/Employee';
import { EmployeeService } from '../../../services/employee.service';
import { CreateEditEmployeeDatetimeModalComponent } from '../../partials/create-edit-employee-datetime-modal/create-edit-employee-datetime-modal.component';

@Component({
    selector: 'app-employee-attendance-to-approve',
    templateUrl: './employee-attendance-list.component.html',
    styleUrls: ['./employee-attendance-list.component.css']
})
export class EmployeeAttendanceListComponent extends FatherOfListComponent<EmployeeDateTime> implements OnInit {
    @ViewChild('filterFormOptions', {static: false})
    public filterFormOptions: TemplateRef<ElementRef>;

    @Input() public isDashboard = false;
    @Input() public employee: Employee;
    @Input() public mode: string;
    @Input() public refreshData: Subject<boolean>;
    @Input() public isStatic = true;

    @Output() public ganttRefresh = new EventEmitter<string>();
    @Output() public getCountEvent = new EventEmitter<string>();

    public noDataMessage: string | null;
    public selectedEmployeeDatetime: EmployeeDateTime;
    public selectedEmployeeDatetimeActionType: 'approved' | 'denied';
    public showHeader = true;
    public title: string | null;
    public toApprove = false;
    public isShift = false;
    public showCreateButton = false;
    public checkedWholePage = false;
    public showProjects = false;
    public showBulkMode = false;
    public checkedEmployeeDatetimeIds: number[] = [];
    public multiAction: 'approved' | 'denied';
    public filterForm: UntypedFormGroup;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public states$: Observable<Array<{ label: string, value: string }>>;
    public types$: Observable<EmployeeDatetimeType[]>;
    public employees$: Observable<Employee[]>;
    public defaultStates = [];
    public filterFormDefaultValues = null;
    public colsConfig = ['35%', '25%', '20%', '20%'];

    public sort: Sort<EmployeeDateTime> = {
        column: 'from',
        direction: SORT_DESC
    };
    private _dateTimeIdsToBeRemoved$ = new BehaviorSubject<Array<number>>([]);
    private _fetchDateTimes$ = new ReplaySubject<void>(1);
    private _filterFormModalRef: NgbModalRef;

    public constructor(
        public fpHelper: FlatpickrHelper,
        public authService: AuthenticationService,
        private _attendanceService: AttendanceService,
        private _employeeService: EmployeeService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _translateService: TranslateService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _router: Router,
        private _datetimeTypesStore: DatetimesTypesStoreService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public getCount(controlName: string): number {
        return this.filterForm.get(controlName).value?.length;
    }

    public ngOnInit(): void {
        this.filterForm = this._fb.group({
            from: [null],
            to: [null],
            types: [null],
            states: [['NEW']],
            employeesIDs: [null],
            onlyShifts: [false]
        });

        this.states$ = this._attendanceService.getDateTimesStates();

        this.types$ = this._datetimeTypesStore.datetimeTypes$
            .pipe(
                map(types => {
                    return types.filter(type => type.is_shift === this.isShift);
                }));

        this.employees$ = this._employeeService.getAllEmployees();

        this._rows$ = combineLatest([
            this._dateTimeIdsToBeRemoved$,
            merge(
                this._fetchDateTimes$,
                this._attendanceService.refreshTimestamps$
            )
                .pipe(
                    tap(() => this._loading$.next(true)),
                    map(() => this._buildParams()),
                    switchMap(params => this._attendanceService.getDateTimeRequestsList(params, this.toApprove, this.filterForm.value)),
                    tap(response => this.getCountEvent.emit(response?.recordsTotal?.toString())),
                    map(response => this._setupList(response)),
                    catchError(() => of([] as Array<EmployeeDateTime>)),
                    tap(() => this._loading$.next(false)),
                    tap(() => this._dateTimeIdsToBeRemoved$.next([]))
                )
        ])
            .pipe(
                map(([dtIdsToBeRemoved, rows]) => this._removeDateTimes(rows, dtIdsToBeRemoved)),
                shareReplay()
            ).pipe(
                tap(rows => this.checkIfPageIsSelectedAll(rows))
            );

        this._init();

        this.title = this._route.snapshot.data.title;

        if (!this.title.startsWith('employee')) {
            this.title = null;
        }

        if (this._router.url === '/employee/attendance/to-approve') {
            this.noDataMessage = this._translateService.instant('employees.table_empty_requests');
            this.filterForm.patchValue({
                states: ['NEW']
            });
            this.defaultStates = ['NEW'];
            this.toApprove = true;
            this.showBulkMode = true;
            this.colsConfig = ['50px', '30%', '20%', '20%', '10%'];
        } else if (this._router.url === '/employee/attendance/history') {
            this.noDataMessage = this._translateService.instant('employees.table_empty_requests');
            this.filterForm.patchValue({
                states: ['APPROVED', 'DENIED', 'NEW']
            });
            this.defaultStates = ['APPROVED', 'DENIED', 'NEW'];
            this.toApprove = false;
            this.showBulkMode = true;
            this.showCreateButton = true;
            if (this.authService.loggedUser.attendance_projects.active) {
                this.showProjects = true;
                this.colsConfig = ['50px', '25%', '20%', '20%', '20%', '10%'];
            }
        } else if (this._router.url === '/dashboard') {
            this.noDataMessage = this._translateService.instant('employees.table_empty_requests');
            this.toApprove = true;
            this.showHeader = false;
            this.itemsPerPage = 5;
            this.filterForm.patchValue({
                states: ['NEW']
            });
            this.defaultStates = ['NEW'];
        } else if (this._router.url.includes('/detail/datetimes')) {
            this.noDataMessage = this._translateService.instant('employees.table_empty_requests');
            this.filterForm.patchValue({
                states: ['APPROVED', 'DENIED', 'NEW'],
                employeesIDs: [this.employee.employee_ID]
            });
            this.showCreateButton = true;
            this.toApprove = false;
            this.defaultStates = ['APPROVED', 'DENIED', 'NEW'];
            this.colsConfig = ['30%', '20%', '20%', '20%', '10%'];
        } else if (this._router.url === '/employee/attendance/shifts/history') {
            this.noDataMessage = this._translateService.instant('employees.table_empty_requests');
            this.filterForm.patchValue({
                states: ['APPROVED', 'DENIED', 'NEW'],
                onlyShifts: true
            });
            this.defaultStates = ['APPROVED', 'DENIED', 'NEW'];
            this.showCreateButton = true;
            this.toApprove = false;
            this.isShift = true;
        } else {
            this.noDataMessage = null;
            this.toApprove = false;
        }

        this.filterFormDefaultValues = this.filterForm.value;

        if (this.refreshData) {
            this.refreshData.subscribe(v => {
                this._fetchListData();
            });
        }

        this._fetchListData();
    }

    public approveOrDeclineDateTime(dt: EmployeeDateTime, action: 'approved' | 'denied', content?: any): void {
        this.selectedEmployeeDatetimeActionType = action;

        if (action === 'denied') {
            this.selectedEmployeeDatetime = dt;

            this._modalService.open(content, {centered: true}).result
                .then(
                    result => {
                        const request = {
                            state: 'denied',
                            note: null
                        };

                        if (result.reason) {
                            request.note = result.reason;
                        }

                        this._attendanceService.saveEmployeeDateTimeState(request as EmployeeDateTime, dt.employee_datetime_ID)
                            .then(() => {
                                this._addItemToBeRemoved(dt);
                                this.ganttRefresh.emit('refresh');
                                this.selectedEmployeeDatetime = null;
                            });
                    },
                    () => this.selectedEmployeeDatetime = null);
        } else {
            this.selectedEmployeeDatetime = dt;

            this._modalService.open(content, {centered: true}).result
                .then(
                    () => {
                        this._attendanceService.saveEmployeeDateTimeState({state: action} as EmployeeDateTime, dt.employee_datetime_ID)
                            .then(() => {
                                this._addItemToBeRemoved(dt);
                                this.ganttRefresh.emit('refresh');
                                this.selectedEmployeeDatetime = null;
                            });
                    },
                    () => this.selectedEmployeeDatetime = null);
        }
    }

    public trackByFn(index: number, employeeDateTime: EmployeeDateTime): number {
        return employeeDateTime.employee_datetime_ID;
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public checkUncheckAll(e) {
        if (this.rows) {
            if (e.target.checked) {
                this.rows.forEach(row => {
                    if (!this.checkedEmployeeDatetimeIds.includes(row.employee_datetime_ID)) {
                        this.checkedEmployeeDatetimeIds.push(row.employee_datetime_ID);
                    }
                });
            } else {
                this.rows.forEach(row => {
                    if (this.checkedEmployeeDatetimeIds.includes(row.employee_datetime_ID)) {
                        this.checkedEmployeeDatetimeIds.splice(this.checkedEmployeeDatetimeIds.indexOf(row.employee_datetime_ID), 1);
                    }
                });
            }
        }
    }

    public rowCheckBoxChecked(e, employeeDatetimeId: number) {
        const isChecked = e.target.checked;
        if (isChecked) {
            this.checkedEmployeeDatetimeIds.push(employeeDatetimeId);
        } else {
            this.checkedEmployeeDatetimeIds.splice(this.checkedEmployeeDatetimeIds.indexOf(employeeDatetimeId), 1);
        }
        this.checkIfPageIsSelectedAll();
    }

    public approveOrDeclineDateTimeChecked(action: 'approved' | 'denied', content?: any) {
        this.multiAction = action;
        if (action === 'approved') {
            this._modalService.open(content, {centered: true}).result
                .then(() => {
                    this._attendanceService.saveEmployeeDateTimesState(action, this.checkedEmployeeDatetimeIds)
                        .then(() => {
                            this.checkedEmployeeDatetimeIds = [];
                            this._fetchListData();
                        });
                }, () => {});
        } else {
            this._modalService.open(content, {centered: true}).result
                .then(
                    result => {
                        this._attendanceService.saveEmployeeDateTimesState(action, this.checkedEmployeeDatetimeIds, result.reason)
                            .then(() => {
                                this.checkedEmployeeDatetimeIds = [];
                                this._fetchListData();
                            });
                    }, () => { });
        }
    }

    public bulkDeleteDateTimeChecked(content?: any) {
        this._modalService.open(content, {centered: true}).result
            .then(() => {
                this._attendanceService.deleteBulkAttendance(this.checkedEmployeeDatetimeIds)
                    .then(() => {
                        this.checkedEmployeeDatetimeIds = [];
                        this._fetchListData();
                    });
            }, () => {});
    }

    public onSubmit(): void {
        this._filterFormModalRef?.close();
        this._fetchListData();
    }

    public openEditModal(): void {
        const modalRef = this._modalService.open(CreateEditEmployeeDatetimeModalComponent, {centered: true});

        modalRef.componentInstance.isEmployeeProfile = true;
        modalRef.componentInstance.isShift = this.isShift;
        modalRef.componentInstance.employee = this.employee ?? this.authService.employee;

        modalRef.result
            .then(
                () => {
                    this._fetchListData();
                    this.ganttRefresh.emit('refetch');
                },
                () => {
                }
            );
    }

    private _addItemToBeRemoved(dt: EmployeeDateTime): void {
        this._dateTimeIdsToBeRemoved$
            .pipe(first())
            .subscribe(ids => this._dateTimeIdsToBeRemoved$.next([...ids, dt.employee_datetime_ID]));
    }

    private _removeDateTimes(rows: Array<EmployeeDateTime>, dateTimeIdsToBeRemoved: Array<number>): Array<EmployeeDateTime> {
        let newRows: Array<EmployeeDateTime> = [...rows];

        dateTimeIdsToBeRemoved.forEach(dateTimeIdToBeRemoved => {
            const dtIndex = newRows.findIndex(row => row?.employee_datetime_ID === dateTimeIdToBeRemoved);

            newRows = typeof dtIndex === 'number' &&
                dtIndex > -1 &&
                [
                    ...newRows.slice(0, dtIndex),
                    ...newRows.slice(dtIndex + 1)
                ] ||
                newRows;
        });

        if (
            rows.length > 0 &&
            !newRows.length
        ) {
            this._fetchListData();
        } else {
            this.itemsTotal = this.itemsTotal - dateTimeIdsToBeRemoved.length;
        }

        return newRows;
    }

    private checkIfPageIsSelectedAll(rows = this.rows) {
        for (const row of rows) {
            if (!this.checkedEmployeeDatetimeIds.includes(row.employee_datetime_ID)) {
                this.checkedWholePage = false;
                return;
            }
        }
        this.checkedWholePage = true;
    }

    protected _fetchListData(): void {
        this._fetchDateTimes$.next();
    }

    protected readonly document = document;
}
