import { Component, HostListener, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { resetStores } from '@datorama/akita';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, shareReplay, tap } from 'rxjs/operators';
import { LoggedInUser } from 'src/app/core/models/LoggedUser';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { MenuItem } from 'src/app/menu/state/menu-item.model';
import { MenuItemQuery } from 'src/app/menu/state/menu-item.query';
import { MenuItemService } from 'src/app/menu/state/menu-item.service';
import { MenuItemStore } from 'src/app/menu/state/menu-item.store';
import { NotificationService } from 'src/app/notification/services/notification.service';
import { Subscription as SubscriptionModel } from 'src/app/setting/models/Subscription';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { SearchComponent } from 'src/app/shared/components/partials/search/search.component';
import { SwitchWorkspaceModalComponent } from 'src/app/shared/components/partials/switch-workspace-modal/switch-workspace-modal.component';
import { environment } from '../../../../../environments/environment';
import { localStorageSafe } from '../../../../shared/functions';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { ChatService } from '../../../../ui/chat/services/chat.service';
import { Version } from '../../../../whats-new/state/version.model';
import { WhatsNewService } from '../../../../whats-new/state/whats-new.service';
import { IOS_DEPLOY_HIDE } from '../../../constants';
import { DomainService } from '../../../services/domain.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    @ViewChildren(NgbDropdown)
    public dropdownElements: QueryList<NgbDropdown>;

    @Input()
    public refreshData: Subject<boolean>;

    public canViewSetting$: Observable<{
        can: boolean
    }>;
    public canViewSubscription$: Observable<{
        can: boolean
    }>;
    public greeting = 'day';
    public innerWidth: number;
    public innerHeight: number;
    public isCollapsed = true;
    public arrowDirection = 'left';
    public submenuCollapsed: Array<boolean> = [];
    public menuItemActive = null;
    public loggedInUser$: Observable<LoggedInUser>;
    public logo: string;
    public activeUrl: string;
    public versionTexts$: Observable<Version[]>;
    public latestVersions$: Observable<Version[]>;
    public menu$ = this._menuQuery.selectAll();
    public menuStats$: Observable<{
        [key: string]: string
    }>;
    public menu: any;
    public showSubscriptionInfo$: Observable<boolean>;
    public subscriptionInfoString$: Observable<string | null>;
    public subscriptionOrTrialEnded$: Observable<boolean>;
    public userDropdownOpened = false;
    public userType$: Observable<'EMPLOYEE' | 'USER'>;
    public showScreenSize = false;
    public docUrl: string;
    public subscriptionData$: Observable<SubscriptionModel>;
    public isNewVersionAvailable = false;
    public isImpersonationLogin = localStorageSafe.getItem('impersonation_admin') === 'true';

    private _subscriptionInfoString$ = new ReplaySubject<string | null>(1);
    private _subscriptionOrTrialEnded$ = new ReplaySubject<boolean>(1);
    private _userType$ = new ReplaySubject<'EMPLOYEE' | 'USER'>(1);

    protected readonly environment = environment;
    protected readonly IOS_DEPLOY_HIDE = IOS_DEPLOY_HIDE;

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    public constructor(
        public notiService: NotificationService,
        public translateService: TranslateService,
        private _authService: AuthenticationService,
        private _domainService: DomainService,
        private _chatService: ChatService,
        private _modalService: NgbModal,
        private _menuQuery: MenuItemQuery,
        private _menuService: MenuItemService,
        private _menuStore: MenuItemStore,
        private _router: Router,
        private _subscriptionService: SubscriptionService,
        private _inputVisibilityService: InputVisibilityService,
        private _whatsNewService: WhatsNewService,
        private _sanitizer: DomSanitizer,
    ) {

        this.showScreenSize = !environment.production;
        this._router.events.forEach((val) => {
            if (val instanceof NavigationEnd) {
                this.activeUrl = val.url;
                this.menu$.subscribe(menu => {
                    this.menu = menu;
                    let endLoop = false;
                    menu.forEach(menuItem => {
                        this.submenuCollapsed[menuItem.routerLink] = false;
                        if (menuItem.regex) {
                            for (let i = 0; i < menuItem.regex.length; i++) {
                                const regex = new RegExp(menuItem.regex[i]);
                                if (regex.test(this.activeUrl)) {
                                    this.activeUrl = menuItem.routerLink;
                                    endLoop = true;
                                }
                            }

                        } else if (!endLoop) {
                            menuItem.submenu?.forEach(submenuItem => {
                                if (submenuItem.routerLink === this.activeUrl) {
                                    this.submenuCollapsed[menuItem.routerLink] = true;
                                    endLoop = true;
                                } else if (submenuItem.regex) {
                                    for (let i = 0; i < submenuItem.regex.length; i++) {
                                        const regex = new RegExp(submenuItem.regex[i]);
                                        if (regex.test(this.activeUrl)) {
                                            this.submenuCollapsed[menuItem.routerLink] = true;
                                            this.activeUrl = submenuItem.routerLink;
                                            endLoop = true;
                                        }
                                    }
                                }
                            });
                        }
                    });
                });
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    @HostListener('window:load', ['$event'])
    public onResize(init = false) {
        this.innerWidth = window.innerWidth;
        this.innerHeight = window.innerHeight;

        if (this.innerWidth >= 1000) {
            if (!init) {
                this.dropdownElements.forEach(el => el.close());
            }

            setTimeout(() => {
                $('.side-nav-list-collapsed > li').each((index, element) => {
                    const rect = element.getBoundingClientRect();
                    element.classList.remove('menu-drop-up');
                    const ul = $(element).children('div').children('ul');
                    const subListHeight = ul.height();

                    if (rect.bottom + subListHeight > this.innerHeight) {
                        element.classList.add('menu-drop-up');
                    }

                });
            }, 0);
        }
    }

    public ngOnInit(): void {
        this.logo = environment.protocol + this._domainService.getTenantNameWithStringAppend('.') + environment.serverUrl + '/api/company-settings/logo';

        this.subscriptionInfoString$ = this._subscriptionInfoString$
            .pipe(shareReplay());

        this.subscriptionOrTrialEnded$ = this._subscriptionOrTrialEnded$
            .pipe(shareReplay());

        this.userType$ = this._userType$
            .pipe(debounceTime(0)); // Need to make use of event loop

        this.refreshLoggedUser();

        this.greeting = this.getGreeting();

        this._menuService.get()
            .subscribe();

        this.subscriptionData$ = this._subscriptionService.subscription$;

        this._menuQuery.select('isCollapsed')
            .subscribe(isCollapsed => this.isCollapsed = isCollapsed);

        this._menuService.refetchMenuStats();

        this.latestVersions$ = this._whatsNewService.getVersions('version', 1)
            .pipe(tap(versions => {
                if (versions[0]?.is_new) {
                    this.isNewVersionAvailable = true;
                }
            }));

        this.versionTexts$ = this._whatsNewService.getVersions('help')
            .pipe(tap(versions => {
                if (versions) {
                    versions.forEach(version => {
                        if (version.is_new) {
                            this.isNewVersionAvailable = true;
                        }
                    });
                }
            }));

        if (this.translateService.currentLang === 'cs') {
            this.docUrl = 'https://docs.speybl.com/cs';
        } else {
            this.docUrl = 'https://docs.speybl.com/en';
        }

        this.menuStats$ = this._menuService.menuStats$;

        this.notiService.getAllNotitificationsByLoggedInUser();

        this.canViewSetting$ = this._authService.hasPermissionTo(['setting.view', 'user.view', 'companySetting.view', 'role.view', 'numberSerie.view', 'companyEmployeePolicy.view', 'companyBranch.view']);
        this.canViewSubscription$ = this._authService.hasPermissionTo('subscription.view');

        setTimeout(() => {
            this.onResize(true);
            $('ul.navbar-nav li.dropdown').hover(
                () => {
                    $(this).find('.nav-link').addClass('active');
                },
                () => {
                    $(this).find('.nav-link').removeClass('active');
                }
            );

            $('ul.navbar-nav li a.nav-link').on('click', () => false);
        }, 1500);

        if (this.refreshData) {
            this.refreshData.subscribe(v => {
                this.refreshLoggedUser(true);
            });
        }

        const collapseDesktopNavbar = localStorageSafe.getItem('desktopNavbar');

        if (collapseDesktopNavbar) {
            this.arrowDirection = collapseDesktopNavbar;
            if (this.arrowDirection === 'right') {
                this.collapseMenu(true);
            }
        }

        this.innerWidth = window.innerWidth;
        this.innerHeight = window.innerHeight;
    }

    public refreshLoggedUser(refresh = false): void {
        this._authService.refreshProfile = refresh;
        this.loggedInUser$ = this._authService.getAuthUser()
            .pipe(
                map(user => {
                    user = {...user};

                    if (user?.workspace?.logoBase64) {
                        user.workspace.logoBase64Safe = this._sanitizer.bypassSecurityTrustResourceUrl(user?.workspace?.logoBase64);
                    } else if (user?.workspace) {
                        user.workspace.logoBase64Safe = 'assets/images/logo_speybl-logo-black.svg';
                    }

                    if (user.employee) {
                        this._userType$.next('EMPLOYEE');
                    } else if (user.employee === null) {
                        this._userType$.next('USER');

                        this.showSubscriptionInfo$ = this._subscriptionService.subscription$
                            .pipe(
                                map((info) => info),
                                tap(data => this._checkSubscriptionInfoString(data)),
                                tap(data => this._checkSubscriptionOrTrialEnded(data)),
                                map(data => data.subscription.status === 'TRIALING'),
                                shareReplay()
                            );
                    }

                    return user;
                })
            );
    }

    public collapseMenu(init = false): void {
        if (window.innerWidth >= 993 || init) {
            $('.navbar-custom.topnav-navbar').toggleClass('sidebar-collapsed');
            $('body').toggleClass('body-sidebar-collapsed');
            $('#side-nav-list').toggleClass('side-nav-list-collapsed');
            $('.desktop-menu').toggleClass('left-menu-collapsed');
            $('.content-page').toggleClass('content-fullscreen');
            $('.topnav-logo').toggleClass('topnav-logo-fullscreen');
            if (!init) {
                this.arrowDirection = this.arrowDirection === 'left' ? 'right' : 'left';
                localStorageSafe.setItem('desktopNavbar', this.arrowDirection);
                this.onResize();
            }
        } else if (window.innerWidth <= 992 && !init) {
            this._menuStore.update({isCollapsed: !this._menuQuery.getValue().isCollapsed});
            $('.navbar-toggle').toggleClass('open');
        }
    }

    public getGreeting(): string {
        const hours = new Date().getHours();

        let greeting = 'day';

        if (hours >= 5 && hours < 12) {
            greeting = 'morning';
        } else if (hours >= 12 && hours < 13) {
            greeting = 'noon';
        } else if (hours >= 13 && hours < 19) {
            greeting = 'afternoon';
        } else if (hours >= 19 && hours < 21) {
            greeting = 'evening';
        } else if (hours >= 21 && hours < 5) {
            greeting = 'evening';
        }

        return greeting;
    }

    public logout(): void {
        this._authService.logout();

        resetStores();
    }

    public onOpenMenuDropdown(event: boolean): void {
        this.userDropdownOpened = event;
    }

    public openSearchModal(): void {
        const ref = this._modalService.open(SearchComponent, {centered: false});
        ref.componentInstance.modalRef = ref;
    }

    public openSwitchWorkspaceModal(): void {
        this._modalService.open(SwitchWorkspaceModalComponent, {centered: true});
    }

    public readNotifications(event: boolean): void {
        if (!event) {
            this.notiService.setViewAt()
                .then(() => this.notiService.getAllNotitificationsByLoggedInUser());
        }
    }

    public redirect(menuItem: MenuItem, redirect = false, first = false, mobile = false): void {
        let routerlink = menuItem.routerLink;
        for (const index in this.submenuCollapsed) {
            this.submenuCollapsed[index] = false;
        }

        if (first && menuItem.submenu) {
            routerlink = menuItem.submenu[0].routerLink;
        }

        if ((redirect && routerlink) || !menuItem.submenu) {
            this._router.navigateByUrl(routerlink)
                .then(() => {
                    this._menuStore.update({isCollapsed: true});

                    $('.navbar-toggle').removeClass('open');
                });
        }

        if (!menuItem.submenu) {
            // if click main menu item with no submenu, set new item as active
            this.menuItemActive = routerlink;
        } else {
            this.submenuCollapsed[routerlink] = !this.submenuCollapsed[routerlink];
            this.scrollSubmenu(menuItem, mobile);
        }
    }

    public clickMenuSubitem(menu: MenuItem): void {
        this.menuItemActive = menu.routerLink;
    }

    private _checkSubscriptionInfoString(data: SubscriptionModel): void {
        let infoString = null;

        infoString = data.subscription.info_string || data.trial_ends_string;

        this._subscriptionInfoString$.next(infoString);
    }

    private _checkSubscriptionOrTrialEnded(data: SubscriptionModel): void {
        try {
            let ends: Date;
            let ended: boolean;

            ends = new Date(data.trial_ends);

            ended = ends < new Date();

            this._subscriptionOrTrialEnded$.next(ended);
        } catch (e) { }
    }

    public hideSensitiveInfo($event: PointerEvent): void {
        this._inputVisibilityService.toggleInputs();
    }

    public scrollSubmenu(menuItem: MenuItem, mobile = false): void {
        setTimeout(() => {
            let paddingConstant = 15;
            let listItemHeight = 50;
            let elementToScroll = '.simplebar-content-wrapper';
            // polozka, na kterou chci scrollovat
            let listItem = document.getElementById('li-menu-' + menuItem.menu_item_ID);
            let sublistItems: any = $(listItem).children('div').children('ul').children('li');
            let sublistItemHeight = sublistItems.first().height();
            let sublistItemsCount = sublistItems.length;
            // vyska submenu
            let subListHeight = sublistItemHeight * sublistItemsCount + paddingConstant;
            // vyska wrapperu pro scrollovani
            let wrapperHeight = $(elementToScroll).height();

            // pokud je mobilni verze
            if (mobile) {
                paddingConstant = 50;
                listItemHeight = 70;
                elementToScroll = '#scrollable-mobile-menu';
                listItem = document.getElementById((mobile ? 'mobile-' : '') + 'li-menu-' + menuItem.menu_item_ID);
                sublistItems = $(listItem).children('div').children('div').children('a');
                sublistItemHeight = sublistItems.first().height();
                sublistItemsCount = sublistItems.length;
                subListHeight = sublistItemHeight * sublistItemsCount + paddingConstant;
                wrapperHeight = $(elementToScroll).height();
            }

            if (subListHeight > 0) {
                const scrollByPx = listItem.offsetTop + listItemHeight + subListHeight - wrapperHeight;

                if (scrollByPx > 0) {
                    $(elementToScroll).animate({scrollTop: scrollByPx}, 400);
                }
            }
        }, 30);
    }

    public showTidio(): void {
        this._chatService.show();
    }
}
