import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CompanyBranch } from 'src/app/setting/models/CompanyBranch';
import { BranchService } from 'src/app/setting/services/branch.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { UserService } from '../../../services/user.service';
import { CreateEditBranchComponent } from '../../partials/create-edit-branch/create-edit-branch.component';

@Component({
    selector: 'app-branch-list',
    templateUrl: './branch-list.component.html',
    styleUrls: ['./branch-list.component.css']
})
export class BranchListComponent extends FatherOfListComponent<CompanyBranch> implements OnInit {
    public canCreate$: Observable<boolean>;

    public sort: Sort<CompanyBranch> = {
        column: 'name',
        direction: SORT_ASC
    };

    private _fetchBranches$ = new ReplaySubject<void>(1);

    public constructor(
        private _branchService: BranchService,
        private _modalService: NgbModal,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this.canCreate$ = this._authService.hasPermissionTo('companyBranch.create')
            .pipe(map(permission => permission.can));

        this._rows$ = this._fetchBranches$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._branchService.getBranchesList(params)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public openEditModal(branch?: CompanyBranch): void {
        const modalRef = this._modalService.open(CreateEditBranchComponent, {size: 'lg', centered: true});

        if (branch) {
            modalRef.componentInstance.branch = branch;
        }

        modalRef.result
            .then(
                () => {
                    this._fetchListData();
                },
                () => { }
            );
    }

    public trackByFn(index: number, branch: CompanyBranch): number | string {
        return branch.company_branch_ID;
    }

    protected _fetchListData(): void {
        this._fetchBranches$.next();
    }
}
