<ng-container *ngIf="(stats$ | async) as stats; else loadingWidgetsTemplate">
    <div *ngIf="isAttendanceCardAvailable && (availableWidgets$ | async)[statsToCheck]?.attendance_cards as availableCards" class="row">
        <div *ngIf="availableCards.days_in_company" class="{{'col-xl-' + 12 / attendanceCols}} col-md-6 mb-3">
            <div class="card h-100 mb-lg-0 shadow show-button-on-hover">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5
                                class="text-muted font-weight-normal my-0 text-truncate"
                                title="{{'employees_datetimes.days_in_company' | translate}}"
                            >
                                <span class="mr-2">🏢</span>
                                <span>{{ 'employees_datetimes.days_in_company' | translate }}</span>
                            </h5>
                        </div>
                        <div class="col-12 fixed-column-height-81px">
                            <h3 class="my-2 py-1 h3-title">{{ stats.days_in_company || '-' }}</h3>
                        </div>
                        <ng-container *ngIf="statsToCheck === 'employee' && employee$ | async as employee">
                            <button *ngIf="!employee.is_archived && employee.permissions.edit" (click)="openEmployeeInCompanyFromModal(stats.in_company_from)" class="btn btn-icon btn-primary btn-sm editButton showed-on-hover">
                                <i class="mdi mdi-pencil"></i>
                            </button>
                        </ng-container>
                        <div *ngIf="stats.in_company_from" class="col-12">
                            <span class="text-muted">{{ 'employees_datetimes.employee_entry' | translate }} {{ stats.in_company_from | date: 'dd.MM.yyyy' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="availableCards.holidays_left" class="{{'col-xl-' + 12 / attendanceCols}} col-md-6 mb-3">
            <div class="card h-100 mb-lg-0 shadow">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5
                                class="text-muted font-weight-normal my-0 text-truncate"
                                title="{{ 'employees_datetimes.holidays_left' | translate }}"
                            >
                                <span class="mr-2">🏝</span>
                                <span>{{ 'employees_datetimes.holidays_left' | translate }}</span>
                            </h5>
                        </div>
                        <div class="col-7 pr-0 fixed-column-height-81px">
                            <h3 class="my-2 py-1 h3-title">
                                {{ stats.holidays.left }} h
                                <span *ngIf="stats.holidays.planned > 0" class="text-mutted font-16">
                                    (-{{ stats.holidays.planned }} h)
                                </span>
                            </h3>
                        </div>
                        <div class="col-5 px-0 fixed-column-height-81px">
                            <app-radial-chart [absoluteValue]="stats.holidays.left / stats.holidays.total * 100"
                                              [labelFormat]="'PERCENT'"
                                              [maxValue]="100"
                                              [height]="80"
                                              [series]="[stats.holidays.left / stats.holidays.total * 100]"
                                              [title]="null"
                                              [colorType]="'solid'"
                                              [ownContainer]="true"
                                              [attendanceStatsWidget]="true"
                                              [tooltips]="{
                                                used_formatted: stats.holidays.used_formatted, used_formatted_days: stats.holidays.used_hours_detail, left_formatted: stats.holidays.left_formatted, left_formatted_days: stats.holidays.remaining_hours_detail
                                              }"
                            ></app-radial-chart>
                        </div>
                        <div class="col-12">
                            <span class="text-muted">{{ stats.holidays.remaining_hours_detail }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="availableCards.sick_days_left && stats?.sick_days?.total > 0" class="{{'col-xl-' + 12 / attendanceCols}} col-md-6 mb-3">
            <div class="card h-100 mb-lg-0 shadow">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5
                                class="text-muted font-weight-normal my-0 text-truncate"
                                title="{{'employees_datetimes.sick_days_left' | translate}}"
                            >
                                <span class="mr-2">🌡</span>
                                <span>{{ 'employees_datetimes.sick_days_left' | translate }}</span>
                            </h5>
                        </div>
                        <div class="col-7 pr-0 fixed-column-height-81px">
                            <h3 class="my-2 py-1 h3-title">{{ stats.sick_days.left }} h</h3>
                        </div>
                        <div class="col-5 px-0 fixed-column-height-81px">
                            <app-radial-chart [absoluteValue]="stats.sick_days.left / stats.sick_days.total * 100"
                                              [labelFormat]="'PERCENT'"
                                              [maxValue]="100"
                                              [height]="80"
                                              [series]="[stats.sick_days.left / stats.sick_days.total * 100]"
                                              [title]="null"
                                              [colorType]="'solid'"
                                              [ownContainer]="true"
                                              [attendanceStatsWidget]="true"
                                              [tooltips]="{
                                                used_formatted: stats.sick_days.used_formatted, used_formatted_days: stats.sick_days.used_hours_detail, left_formatted: stats.sick_days.left_formatted, left_formatted_days: stats.sick_days.remaining_hours_detail
                                              }"
                            ></app-radial-chart>
                        </div>
                        <div class="col-12">
                            <span class="text-muted">{{ stats.sick_days.remaining_hours_detail }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="availableCards.work_chart" class="{{'col-xl-' + 12 / attendanceCols}} col-md-6 mb-3">
            <!--<editor-fold desc="Work chart">-->
            <div class="card h-100 shadow">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5
                                class="text-muted font-weight-normal mt-0 mb-0 text-truncate"
                                title="{{ 'employees_datetimes.work_chart' | translate }}"
                            >
                                <span class="mr-2">⏱️</span>
                                <span>{{ 'employees_datetimes.work_chart' | translate }}</span>
                            </h5>
                        </div>
                        <div class="col-6 pr-0 fixed-column-height-81px">
                            <h3 class="my-2 py-1 h3-title">
                                <span>{{ stats.work_chart_week?.worked_total }}</span> /
                                <span>{{ stats.work_chart_week?.expected_total }}</span> h
                            </h3>
                        </div>
                        <div class="col-6 px-0 fixed-column-height-81px">
                            <app-bar-echart [labels]="days" [series]="stats.work_chart_week.series"></app-bar-echart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loadingWidgetsTemplate>
    <div class="row">
        <div class="col-12 col-lg-4">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '120px', 'margin': '15px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="2">
                </ngx-skeleton-loader>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '120px', 'margin': '15px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="2">
                </ngx-skeleton-loader>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '120px', 'margin': '15px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="2">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>
