<!--<editor-fold desc="MODALY">-->
<ng-template #filterFormOptions let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees_datetimes.adjust_filters' | translate }}</span>
            </p>
            <button (click)="modal.dismiss()" aria-label="Close" class="close">
        <span aria-hidden="true" class="font-24 text-muted">
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form (ngSubmit)="onSubmit()" [formGroup]="filterForm" class="filter-form">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'employees_datetimes.filter_date_from' | translate }}</label>
                            <input [locale]="locale$ | async" allowInput="true" altFormat="d.m.Y"
                                   altInput="true"
                                   class="form-control"
                                   dateFormat="Y-m-d"
                                   formControlName="from"
                                   mwlFlatpickr
                                   (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                   type="text">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'employees_datetimes.filter_date_to' | translate }}</label>

                            <input [locale]="locale$ | async" allowInput="true" altFormat="d.m.Y"
                                   altInput="true"
                                   class="form-control"
                                   dateFormat="Y-m-d"
                                   formControlName="to"
                                   mwlFlatpickr
                                   (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                   type="text">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-group">{{ 'employees_datetimes.filter_type' | translate }}</label>
                            <div>
                                <ng-select
                                    [closeOnSelect]="false"
                                    [items]="datetimeTypes$ | async"
                                    [multiple]="true"
                                    bindLabel="name"
                                    bindValue="employee_datetime_type_ID"
                                    class="custom"
                                    formControlName="types"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-group">{{ 'employees_datetimes.group_by_company_group' | translate }}</label>
                            <div>
                                <input (click)="setCheckboxCollapse()" [attr.aria-expanded]="!isCheckboxCollapsed" aria-controls="collapse" data-switch="success" formControlName="group_by_company_group" id="group_by_company_group"
                                       name="group_by_company_group" type="checkbox">
                                <label for="group_by_company_group">&nbsp;</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div [(ngbCollapse)]="!isCheckboxCollapsed" class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-group">{{ 'employees_datetimes.expend_all_company_groups' | translate }}</label>
                            <div>
                                <input data-switch="success" formControlName="expend_all_company_groups" id="expend_all_company_groups" name="expend_all_company_groups" type="checkbox">
                                <label for="expend_all_company_groups">&nbsp;</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'analysis.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!--</editor-fold desc="MODALY">-->

<div [ngClass]="{'position-static': isStatic}" class="card shadow h-100">
    <app-help *ngIf="isDashboard" name="dashboard.employee_attendance_chart"></app-help>
    <app-help *ngIf="!isDashboard" name="employee.detail.attendance.chart"></app-help>
    <div class="card-header py-3">
        <div class="row">
            <div class="col text-left my-auto">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                    {{ 'employees_datetimes.gantt_title' | translate }}
                </p>
            </div>
            <div class="col-auto text-right">
                <ng-container *ngIf="showSwitch">
                    <label class="form-group mr-2">{{ 'employees_datetimes.daily_summary' | translate }}</label>
                    <input (change)="switchToPlan()"
                           data-switch="success"
                           id="show_planned"
                           name="show_planned"
                           type="checkbox">
                    <label class="mr-3"
                           for="show_planned">
                        &nbsp;</label>
                </ng-container>
                <button *ngIf="allowBulkMode"
                    (click)="toggleBulkMode()"
                    class="btn btn-light btn-icon mr-2"
                    type="button"
                >
                    <i class="mdi mdi-format-list-bulleted"></i>
                </button>
                <button
                    (click)="openFilterFormOptions()"
                    class="btn btn-primary btn-icon"
                    type="button"
                    [formGroup]="filterForm"
                    [defaultValue]="filterFormDefaultValues"
                >
                    <i class="mdi mdi-filter-outline"></i>
                </button>
            </div>
        </div>
    </div>

    <div [class.gantt-bulk-mode]="bulkMode" class="card-body">
        <ng-container *ngIf="bulkMode">
            <div class="row">
                <div class="col-12">
                    <p>{{ 'employees_datetimes.bulk_mode_active' | translate}}</p>
                </div>
                <div class="col-12">
                    <p>
                        {{ 'employees_datetimes.bulk_mode_selected_count' | translate}} {{ bulkSelected?.length}}
                        <button (click)="openBulkModal()" class="btn btn-sm btn-primary ml-2">{{ 'employees_datetimes.bulk_mode_create' | translate}}</button>
                    </p>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="usedCompanyGroups && ganttCategories?.length && !chartLoading; else loadingTemplate">
            <ng-container *ngIf="usedCompanyGroups.length; else noDataTemplate">
                <div class="table-fixed-column-wrapper">
                    <div
                        class="table-responsive pt-3"
                        style="padding-bottom: 30px;"
                    >
                        <table
                            class="table table-gantt"
                            style="table-layout: fixed;"
                        >
                            <tr class="border-0"></tr>
                            <tr *ngIf="ganttCategories" class="categories-row top mt-5">
                                <td class="separator fixed-column border-top-0">&nbsp;</td>

                                <td
                                    *ngFor="let category of ganttCategories"
                                    [class.highlight]="category.is_today"
                                    [class.separator]="category.is_last_of_period"
                                >
                                    <div>
                                        <span>{{ category.formatted_short }}</span>

                                        <span
                                            *ngIf="category.is_first_of_period"
                                            class="extended-label-first top"
                                        >{{ category.day_month }}</span>
                                    </div>
                                </td>
                            </tr>
                            <ng-container *ngFor="let data of usedCompanyGroups">
                                <tr *ngIf="data.name !== 'all'">
                                    <td
                                        (click)="refreshDataByTeam(data.company_group_ID, true)"
                                        class="row-label separator fixed-column row-label-category cursor-pointer">
                                        <strong class="company-group-name"> {{ data.name }} </strong>
                                        <div class="w-auto">
                                            <div *ngIf="loading[data.company_group_ID]" class="spinner-border spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <i
                                                *ngIf="this.collapsedCompanyGroups[data.company_group_ID]"
                                                class="mdi mdi-menu-right ml-1 mdi-24px d-inline-block menu-arrow-collapse"
                                            ></i>
                                            <i
                                                *ngIf="!this.collapsedCompanyGroups[data.company_group_ID]"
                                                class="mdi mdi-menu-down ml-1 mdi-24px d-inline-block menu-arrow-collapse"
                                            ></i>
                                        </div>

                                    </td>

                                    <td [attr.colspan]="ganttCategories?.length">&nbsp;</td>
                                </tr>

                                <ng-container *ngIf="ganttData[data.company_group_ID] && !this.collapsedCompanyGroups[data.company_group_ID]">
                                    <tr *ngFor="let employee of ganttData[data.company_group_ID]"
                                        [class.highlight]="employee.highlight_employee">
                                        <td class="row-label separator fixed-column">
                                            <app-employee-box-avatar [canView]="employee.employee.permissions.view" [employeeID]="employee.employee.employee_ID" [fullname]="employee.employee.label" [sizeRatio]="0.9" class="pr-1 background-box"></app-employee-box-avatar>
                                        </td>

                                        <td *ngFor="let employeeDataItem of employee.data; let i = index"
                                            [attr.colspan]="employeeDataItem.duration_days > 1 ? employeeDataItem.duration_days : ''"
                                            [class.highlight]="ganttCategories[i]?.is_today"
                                            [class.separator]="ganttCategories[i]?.is_last_of_period">
                                            <ng-template #popContent>
                                                <ng-container *ngIf="employeeDataItem.show_tooltip">
                                                    <p *ngFor="let employeeDatetimePart of employeeDataItem.parts" class="mb-0" [innerHTML]="employeeDatetimePart.label ? employeeDatetimePart.label : 'global.gantt_tooltip_label' | translate"></p>
                                                </ng-container>
                                            </ng-template>

                                            <div [ngbPopover]="popContent" class="gantt-box position-relative" container="body"
                                                 placement="top"
                                                 popoverTitle="{{ganttCategories[i]?.formatted_short + ' ' + ganttCategories[i]?.formatted}}"
                                                 triggers="mouseenter:mouseleave">
                                                <svg [attr.width]="'20'" [style.height.px]="'20'" [ngStyle]="{'border': employeeDataItem.border_color ? '1px solid ' + employeeDataItem.border_color : 'none'}" class="bar-group">
                                                    <ng-container *ngFor="let employeeDatetimePart of employeeDataItem.parts">
                                                        <rect [attr.width]="!employeeDatetimePart.duration_percents ? defaultWidth : (employeeDatetimePart.duration_percents * defaultWidth / 100)" [attr.x]="!employeeDatetimePart.offset_percents ? 0 : (employeeDatetimePart.offset_percents * defaultWidth)" [style.fill]="employeeDatetimePart.color"
                                                              class="bar-progress"
                                                              [ngClass]="{'rect-datetime': employeeDatetimePart.employee_datetime_ID !== null}"
                                                              height="20"
                                                              y="0">
                                                        </rect>

                                                        <ng-container *ngIf="employeeDatetimePart.is_shift">
                                                            <pattern height="1" id="diaHatch{{employeeDatetimePart.employee_datetime_ID}}" patternTransform="rotate(45)" patternUnits="userSpaceOnUse" width="4">
                                                                <rect [style.fill]="getStripeColor(employeeDatetimePart.color)" height="1" width="2" x="0" y="0"/>
                                                            </pattern>
                                                            <rect [attr.width]="!employeeDatetimePart.duration_percents ? defaultWidth : (employeeDatetimePart.duration_percents * defaultWidth / 100)" [attr.x]="!employeeDatetimePart.offset_percents ? 0 : (employeeDatetimePart.offset_percents * defaultWidth)" [style.fill]="'url(#diaHatch'+employeeDatetimePart.employee_datetime_ID+')'"
                                                                  class="bar-progress"
                                                                  height="20"
                                                                  y="0">
                                                            </rect>
                                                        </ng-container>

                                                        <rect (click)="openDatetimeEditModal(employeeDatetimePart, employee.employee, employee.highlight_employee, employee.showModal)" [attr.width]="!employeeDatetimePart.duration_percents ? defaultWidth : (employeeDatetimePart.duration_percents * defaultWidth / 100)" [attr.x]="!employeeDatetimePart.offset_percents ? 0 : (employeeDatetimePart.offset_percents * defaultWidth)"
                                                              [style.fill]="'transparent'"
                                                              [ngClass]="{'rect-bulk-mode': isRectSelected(employeeDatetimePart, employee.employee.employee_ID), 'can-bulk-create': employee.highlight_employee || employee.showModal, 'rect-datetime': employeeDatetimePart.employee_datetime_ID !== null}"
                                                              class="bar-progress"
                                                              height="20"
                                                              y="0">
                                                        </rect>
                                                    </ng-container>
                                                </svg>

                                                <span *ngIf="employeeDataItem.to_be_approved || employeeDataItem.highlight_daily_hour_work_fond"
                                                      [ngClass]="{'badge-blue': employeeDataItem.to_be_approved, 'badge-red': employeeDataItem.highlight_daily_hour_work_fond}"
                                                      class="badge"></span>
                                                <span *ngIf="(employee.highlight_employee || employee.showModal) && !bulkMode" (click)="openDatetimeEditModal(employeeDataItem.parts[0], employee.employee, employee.highlight_employee, employee.showModal, true)" class="plus-icon text-green">
                                                            <i class="mdi mdi-plus-circle"></i>
                                                        </span>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>

                            <tr *ngIf="ganttCategories" class="categories-row">
                                <td class="separator fixed-column border-bottom-0">&nbsp;</td>

                                <td
                                    *ngFor="let category of ganttCategories"
                                    [class.highlight]="category.is_today"
                                    [class.separator]="category.is_last_of_period"
                                >
                                    <div>
                                        <span>{{ category.formatted_short }}</span>

                                        <span
                                            *ngIf="category.is_first_of_period"
                                            class="extended-label-first"
                                        >{{ category.day_month }}</span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div *ngIf="!isDashboard" class="gantt-chart-legend">
                    <span *ngFor="let t of datetimeTypes$ | async" class="color-box-wrap">
                        <span [style.background-color]="t?.color" class="color-box"></span>
                        <span class="color-box-label">{{ t?.name }}</span>
                    </span>
                    <span *ngIf="isShift" class="color-box-wrap">
                        <span class="color-box">
                            <svg class="bar-group rounded-sm" height="20" width="20">
                                <pattern height="1" id="diaHatchForLegend" patternTransform="rotate(45)" patternUnits="userSpaceOnUse" width="4">
                                    <rect [style.fill]="'rgba(0,0,0,0.3)'" height="1" width="2" x="0" y="0"/>
                                </pattern>
                                <rect [style.fill]="'url(#diaHatchForLegend)'" class="bar-progress" height="20" width="20" x="0" y="0"></rect>
                            </svg>
                        </span>
                        <span class="color-box-label">{{ 'employees_datetimes.attendance_type_shift' | translate }}</span>
                    </span>
                </div>
            </ng-container>

            <ng-template #noDataTemplate>
                <div class="text-center text-muted">{{ 'global.empty_widget_error' | translate }}</div>
            </ng-template>
        </ng-container>

        <ng-template #loadingTemplate>
                    <div class="item">
                        <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="{{isDashboard ? '4' : '7'}}"></ngx-skeleton-loader>
                    </div>
        </ng-template>
    </div>
</div>
