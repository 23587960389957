import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { PropertyCategory } from 'src/app/property/models/PropertyCategory';
import { PropertyService } from 'src/app/property/services/property.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { UserService } from '../../../../setting/services/user.service';
import { CreateEditCategoryComponent } from '../create-edit-category/create-edit-category.component';

@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent extends FatherOfListComponent<PropertyCategory> implements OnInit {
    public canCreate$: Observable<boolean>;

    public sort: Sort<PropertyCategory> = {
        column: 'name',
        direction: SORT_ASC
    };

    private _fetchCategories$ = new ReplaySubject<void>(1);

    public constructor(
        private _modalService: NgbModal,
        private _propertyService: PropertyService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this.canCreate$ = this._authService.hasPermissionTo('propertyCategory.create')
            .pipe(map(permission => permission.can));

        this._rows$ = this._fetchCategories$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._propertyService.getPropertyCategoriesList(params)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public deleteCategory(categoryID: number): void {
        this._propertyService.deleteCategory(categoryID)
            .then(() => this._fetchCategories$.next())
            .catch(() => { });
    }

    public openEditModal(propertyCategoryID?: number): void {
        const modalRef = this._modalService.open(CreateEditCategoryComponent, {centered: true});

        if (propertyCategoryID) {
            modalRef.componentInstance.categoryID = propertyCategoryID;
        }

        modalRef.result
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    public trackByFn(index: number, category: PropertyCategory): number {
        return category.property_category_ID;
    }

    protected _fetchListData(): void {
        this._fetchCategories$.next();
    }
}
