import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { Property } from 'src/app/property/models/Property';
import { PropertyService } from 'src/app/property/services/property.service';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { UserService } from '../../../../setting/services/user.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { CustomField } from '../../../../shared/models/CustomField';
import { CreateEditPropertyComponent } from '../create-edit-property/create-edit-property.component';
import { MultiAssignUnassignPropertyComponent } from '../multi-assign-unassign-property/multi-assign-unassign-property.component';

@Component({
    selector: 'app-property-list',
    templateUrl: './property-list.component.html',
    styleUrls: ['./property-list.component.css']
})
export class PropertyListComponent extends FatherOfListComponent<Property> implements OnInit {
    @ViewChild('filterFormOptions', {static: false})
    public filterFormOptions: TemplateRef<ElementRef>;

    public canCreate$: Observable<boolean>;
    public canExport$: Observable<boolean>;
    public employees$: Observable<Employee[]>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public unassignedEmployee = false;
    public filterForm: UntypedFormGroup;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public deletedFilter = null;
    public properties$: Observable<any>;
    public checkedWholePage = false;
    public checkedPropertyIds: number[] = [];
    public customFieldColumns: CustomField[];

    public selectFilterValues = [
        {
            name: this._translateService.instant('properties.states.IN_USE'),
            value: 'IN_USE',
        }, {
            name: this._translateService.instant('properties.states.IN_STOCK'),
            value: 'IN_STOCK',
        }, {
            name: this._translateService.instant('properties.states.ARCHIVED'),
            value: 'ARCHIVED',
        }, {
            name: this._translateService.instant('properties.states.LOANED'),
            value: 'LOANED',
        }, {
            name: this._translateService.instant('properties.states.IN_SERVICE'),
            value: 'IN_SERVICE',
        }, {
            name: this._translateService.instant('properties.states.OTHER'),
            value: 'OTHER',
        },
    ];

    public categories$: Observable<any>;
    public selectedProperty: Property | null;
    public sort: Sort<Property> = {
        column: 'name',
        direction: SORT_ASC
    };
    private _filterFormModalRef: NgbModalRef;
    private _fetchProperties$ = new ReplaySubject<void>(1);

    public constructor(
        public fpHelper: FlatpickrHelper,
        private _modalService: NgbModal,
        private _propertyService: PropertyService,
        private _translateService: TranslateService,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _fb: UntypedFormBuilder,
        private _employeeService: EmployeeService,
        private _companyService: CompanyService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public get categoriesIDsCount(): number {
        return this.filterForm.get('categoriesIDs').value?.length;
    }

    public get EmployeesCount(): number {
        return this.filterForm.get('employeesIDs').value?.length;
    }

    public setUnassignedEmployee(): void {
        this.unassignedEmployee = !this.unassignedEmployee;
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;

        this.filterForm = this._fb.group({
            employeesIDs: [null],
            from: [null],
            to: [null],
            states: [null],
            categoriesIDs: [null],
            unassignedEmployee: false,
        });

        this.useLocalStorage(this.filterForm, 'propertyList');

        this.employees$ = this._employeeService.getAllEmployees();

        this.canCreate$ = this._authService.hasPermissionTo('property.create')
            .pipe(map(permission => permission.can));

        this.canExport$ = this._authService.hasPermissionTo('property.view')
            .pipe(map(permission => permission.can));

        this._rows$ = this._fetchProperties$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                tap(params => this.properties$ = this._propertyService.getPropertiesList(params, this.filterForm.value)),
                switchMap(params => this.properties$),
                map(response => this._setupList(response)),
                tap((employees) => {
                    if (!this.customFieldColumns) {
                        this.customFieldColumns = employees.length > 0 ? employees[0].custom_fields : [];
                    }
                }),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this.categories$ = this._propertyService.getAllPropertyCategories();

        this._init();

        this._fetchListData();
    }

    public deleteProperty(propertyID: number): void {
        this._propertyService.deleteProperty(propertyID)
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    public downloadCSVExport(): void {
        this._propertyService.downloadExportedProperties(this._buildParams(), this.deletedFilter);
    }

    public openEditModal(properties: Property[]): void {
        const modalRef = this._modalService.open(CreateEditPropertyComponent, {centered: true});

        modalRef.componentInstance.properties = properties;

        modalRef.result
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    public openThumbnailModal(content: any, property: Property): void {
        this.selectedProperty = property;

        this._modalService.open(content, {centered: true, size: 'lg'}).result
            .then(
                () => this.selectedProperty = null,
                () => this.selectedProperty = null
            );
    }

    public processInputArray(input: { name: string, value: null | 'DELETED' | 'NOT_DELETED' }): void {
        if (input) {
            this.deletedFilter = input.value;
        }

        this._fetchListData();
    }

    public trackByFn(index: number, property: Property): number {
        return property.property_ID;
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public onFilterSubmit(): void {
        this.saveFormConfigToLocalStorage(this.filterForm.value, 'form');
        this._filterFormModalRef?.close();
        this._fetchListData();
    }

    public checkUncheckAll(e: any) {
        if (e.target.checked) {
            this.rows.forEach(row => {
                if (!this.checkedPropertyIds.includes(row.property_ID)) {
                    this.checkedPropertyIds.push(row.property_ID);
                }
            });
        } else {
            this.rows.forEach(row => {
                if (this.checkedPropertyIds.includes(row.property_ID)) {
                    this.checkedPropertyIds.splice(this.checkedPropertyIds.indexOf(row.property_ID), 1);
                }
            });
        }
    }

    public rowCheckBoxChecked(e: any, propertyID: number) {
        const isChecked = e.target.checked;
        if (isChecked) {
            this.checkedPropertyIds.push(propertyID);
        } else {
            this.checkedPropertyIds.splice(this.checkedPropertyIds.indexOf(propertyID), 1);
        }
        this.checkIfPageIsSelectedAll();
    }

    public checkIfPageIsSelectedAll(rows = this.rows) {
        for (const row of rows) {
            if (!this.checkedPropertyIds.includes(row.property_ID)) {
                this.checkedWholePage = false;
                return;
            }
        }
        this.checkedWholePage = true;
    }

    public openMultiUnassignModal(): void {
        const modalRef = this._modalService.open(MultiAssignUnassignPropertyComponent, {centered: true});

        modalRef.componentInstance.propertyIDs = this.checkedPropertyIds;
        modalRef.componentInstance.action = 'assign';

        modalRef.result.then(
            () => this._fetchListData(),
            () => { }
        );
    }

    public setColumnsWidth(obj: { category: boolean, code: boolean, date: boolean, employee: boolean, image: boolean, serial_number: boolean }): Array<string> {
        const arr = ['50px'];
        const tmpObj = {...obj};

        if (this.rows?.length > 0 && this.rows[0].custom_fields) {
            const customFields = this.rows[0].custom_fields;
            customFields.forEach(field => tmpObj[field.key] = true);
        }

        let count = 1;
        const objArr = Object.keys(tmpObj);

        for (const item of objArr) {
            if (tmpObj[item]) {
                count++;
            }
        }

        for (let i = 0; i < count; i++) {
            arr.push((90 / count).toString() + '%');
        }

        arr.push('10%');

        return arr;
    }

    protected _fetchListData(): void {
        this._fetchProperties$.next();
        this.checkedPropertyIds = [];
        this.checkedWholePage = false;
    }
}
