import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CustomField } from '../../../../shared/models/CustomField';
import { WidgetTemplate } from '../../../../shared/models/WidgetTemplate';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';
import { FatherOfListComponent, Sort, SORT_ASC } from '../../../../ui';
import { CompanyService } from '../../../services/company.service';
import { UserService } from '../../../services/user.service';
import { CreateEditWidgetTemplateModalComponent } from '../../partials/create-edit-widget-template-modal/create-edit-widget-template-modal.component';

@Component({
    selector: 'app-widget-templates-list',
    templateUrl: './widget-templates-list.component.html',
    styleUrls: ['./widget-templates-list.component.css']
})
export class WidgetTemplatesListComponent extends FatherOfListComponent<WidgetTemplate> implements OnInit {
    @Input() public definitions: CustomField[];

    public canEdit = false;
    public defaultWidgetTemplateForm: UntypedFormGroup;
    public defaultWidgetTemplateID$: Observable<number>;
    public sort: Sort<WidgetTemplate> = {
        column: 'name',
        direction: SORT_ASC
    };

    private _fetchWidgetTemplates$ = new ReplaySubject<void>(1);

    public constructor(
        private _widgetTemplatesService: WidgetTemplatesService,
        private _modalService: NgbModal,
        private _fb: UntypedFormBuilder,
        private _companyService: CompanyService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this.canEdit = this._authService.checkPermission('companySetting.edit');

        this.defaultWidgetTemplateForm = this._fb.group({
            'widget_templates.default_id': [null]
        });

        this.defaultWidgetTemplateID$ = this._companyService.getSettingsByKeys('widget_templates.default_id')
            .pipe(
                tap(setting => {
                    this.defaultWidgetTemplateForm.patchValue({
                        'widget_templates.default_id': parseInt(setting['widget_templates.default_id'], 10)
                    });
                }),
            );

        this._rows$ = this._fetchWidgetTemplates$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._widgetTemplatesService.getWidgetTemplateList(params)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();
        this._fetchListData();
    }

    public openEditModal(widgetTemplate: WidgetTemplate): void {
        this._changeDetectorRef.detectChanges();
        const modalRef = this._modalService.open(CreateEditWidgetTemplateModalComponent, {centered: true});
        console.log(this.definitions);

        modalRef.componentInstance.widgetTemplate = widgetTemplate;
        modalRef.componentInstance.customFieldDefinitions = this.definitions;

        modalRef.result
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    protected _fetchListData(): void {
        this._fetchWidgetTemplates$.next();
    }

    public trackByFn(index: number, item: WidgetTemplate): number | string {
        return item.id;
    }

    public onSubmitDefaultTemplate(): void {
        void this._companyService.saveSettingsByKeys({'widget_templates.default_id': this.defaultWidgetTemplateForm.value['widget_templates.default_id']});
    }
}
