import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, first, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { FatherOfListComponent, Sort, SORT_DESC } from 'src/app/ui';
import { UserService } from '../../setting/services/user.service';
import { InternalEntity } from '../interface/internal-entity.interface';
import { ScheduledTask } from '../interface/scheduled-task.interface';
import { ScheduledTaskCreateEditModalComponent } from '../presentation/scheduled-task-create-edit-modal.component';
import { ScheduledTaskService } from '../service/scheduled-task.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-scheduled-task-list',
    templateUrl: './scheduled-task-list.component.html',
    styleUrls: ['./scheduled-task-list.component.css']
})
export class ScheduledTaskListComponent extends FatherOfListComponent<ScheduledTask> implements OnInit {
    @Input()
    public dataOnly = false;

    @Input()
    public mode = 'default';

    @Input()
    public set entity(entity: InternalEntity) {
        this.entitySet = true;

        this._entity$.next(entity);
    }

    public showHeader = true;

    public canCreate$: Observable<boolean>;

    public entitySet = false;

    public sort: Sort<ScheduledTask> = {
        column: 'created_at',
        direction: SORT_DESC
    };

    private _fetchScheduledTasks$ = new ReplaySubject<void>(1);

    private _entity$ = new BehaviorSubject<InternalEntity | null>(null);

    public constructor(
        private _modalService: NgbModal,
        private _scheduledTaskService: ScheduledTaskService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {

        this.showHeader = this.mode !== 'compact';

        this.canCreate$ = this._authService.hasPermissionTo('scheduledTask.create')
            .pipe(map(permission => permission.can));

        this._rows$ = this._fetchScheduledTasks$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                withLatestFrom(this._entity$),
                switchMap(([params, entity]) => this._scheduledTaskService.getList(params, entity)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public openEditModal(scheduledTask?: ScheduledTask): void {
        this._entity$
            .pipe(first())
            .subscribe(entity => {
                const modalRef = this._modalService.open(ScheduledTaskCreateEditModalComponent, {centered: true});

                modalRef.componentInstance.entity = entity;
                modalRef.componentInstance.scheduledTask = scheduledTask;

                modalRef.componentInstance.deleted
                    .pipe(
                        first(),
                        switchMap(() => this._scheduledTaskService.delete(scheduledTask))
                    )
                    .subscribe(() => {
                        modalRef.close();

                        this._fetchListData();
                    });

                modalRef.result
                    .then(
                        values => {
                            if (!values) { // delete
                                return;
                            }

                            let stream$;

                            if (scheduledTask) {
                                stream$ = this._scheduledTaskService.update(scheduledTask.scheduled_task_ID, values);
                            } else {
                                stream$ = this._scheduledTaskService.create(values);
                            }

                            stream$
                                .subscribe(() => this._fetchListData());
                        },
                        () => { }
                    );
            });
    }

    public trackByFn(index: number, scheduledTask: ScheduledTask): number {
        return scheduledTask.scheduled_task_ID;
    }

    protected _fetchListData(): void {
        this._fetchScheduledTasks$.next();
    }
}
