import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { Tag } from 'src/app/tag/state/tag.model';
import { TagService } from 'src/app/tag/state/tag.service';
import { TagListComponent } from '../../sections/tag-list/tag-list.component';

@Component({
    selector: 'app-tag-modal',
    templateUrl: './tag-modal.component.html',
    styleUrls: ['./tag-modal.component.css']
})
export class TagModalComponent implements OnInit, OnDestroy {
    public entityTags: Tag[];
    public entityID: number;
    public entityType: string;
    public category: string;
    public modalTitle: string;
    public canEdit$: Observable<boolean>;
    public allTagsSubscription: Subscription;
    public allTags: Tag[];
    public allTagsAutoComplete: string[];
    public createEditTagForm: UntypedFormGroup;
    public submitted = false;
    public refetchTags$$ = new ReplaySubject<void>();
    public parentInstance: TagListComponent;

    private _tag: Tag;

    public constructor(
        public activeModal: NgbActiveModal,
        private _tagService: TagService,
        private _fb: UntypedFormBuilder,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.createEditTagForm.controls;
    }

    public get tag(): Tag {
        return this._tag;
    }

    public set tag(tag: Tag) {
        this._tag = tag;
        this.createEditTagForm.patchValue({
            label: this.tag.label,
        });
    }

    public ngOnInit(): void {
        this.allTagsSubscription = this._tagService.getAllTags(this.entityType, this.category)
            .subscribe(allTags => {
                this.allTags = allTags;
                this.allTagsAutoComplete = allTags.map(tg => tg.label);
            });

        this.createEditTagForm = this._fb.group({
            label: ['', Validators.required],
        });

        this.canEdit$ = of(true);
    }

    public ngOnDestroy(): void {
        this.allTagsSubscription?.unsubscribe();
    }

    public onSubmit(f: UntypedFormGroup): void {
        this.submitted = true;

        if (f.invalid) {
            return;
        }

        const tag = this.allTags?.find(tg => tg.label === f.value.label);

        let promise: Promise<any>;
        if (tag) {
            promise = this._tagService.attachCreateTag(this.entityID, this.entityType, tag, null, this.category);
        } else {
            promise = this._tagService.attachCreateTag(this.entityID, this.entityType, null, f.value.label, this.category);
        }

        this.entityTags.push({...tag, label: f.value.label});

        promise.then(() => this.refetchTags$$.next());
        f.patchValue({label: ''});
    }

    public detachTag(tag: Tag): void {
        this.entityTags = this.entityTags.filter(tg => tg.tag_ID !== tag.tag_ID);
        this.parentInstance.entityTags = this.entityTags;
        this._tagService.detachTag(this.entityID, this.entityType, tag)
            .then(() => this.refetchTags$$.next());
    }
}
