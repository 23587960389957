import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { Employee } from '../../../models/Employee';

@Component({
    selector: 'app-create-employee-termination-modal',
    templateUrl: './create-employee-termination-modal.component.html',
    styleUrls: ['./create-employee-termination-modal.component.css']
})
export class CreateEmployeeTerminationModalComponent implements OnInit {
    public employee: Employee;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public submitted = false;
    public employeeTerminated = false;
    public terminationEditForm: UntypedFormGroup;
    public subscription$ = this._subscriptionService.subscription$;

    public constructor(
        public activeModal: NgbActiveModal,
        public fpHelper: FlatpickrHelper,
        private _employeeService: EmployeeService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _subscriptionService: SubscriptionService
    ) {
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.terminationEditForm.controls;
    }

    public ngOnInit(): void {
        this.terminationEditForm = this._fb.group({
            termination_end_date: ['', Validators.required],
            termination_reason: ['RESIGNATION', Validators.required],
            termination_description: [''],
        });

        if (this.employee.termination.terminated) {
            this.terminationEditForm.patchValue(this.employee.termination);
            this.employeeTerminated = true;
            this.terminationEditForm.disable();
        }
    }

    public onSubmit(cancel: boolean): void {
        this.submitted = true;

        if (!this.terminationEditForm.valid && !cancel) {
            return;
        }

        const values = this.terminationEditForm.value;
        values.cancel = cancel;

        this._employeeService.terminateEmployee(values, this.employee.employee_ID)
            .then(() => this.activeModal.close());
    }

    public isTerminatedInPast(): boolean {
        return new Date(this.employee.termination?.termination_end_date) < new Date();
    }
}
