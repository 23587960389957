import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { Property } from 'src/app/property/models/Property';
import { PropertyService } from 'src/app/property/services/property.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';

@Component({
    selector: 'app-assign-property',
    templateUrl: './assign-property.component.html',
    styleUrls: ['./assign-property.component.css']
})
export class AssignPropertyComponent implements OnInit {
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public employees$: Observable<Array<Employee>>;
    public property: Property;
    public assignPropertyForm: UntypedFormGroup;
    public submitted = false;

    public constructor(
        public activeModal: NgbActiveModal,
        public fpHelper: FlatpickrHelper,
        private _employeeService: EmployeeService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _propertyService: PropertyService
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.assignPropertyForm.controls;
    }

    public ngOnInit(): void {
        this.assignPropertyForm = this._fb.group({
            employee_ID: [null, Validators.required],
            handover_date: ['', Validators.required],
        });

        this.employees$ = this._employeeService.getAllEmployees(false, 'select');
    }

    public onSubmit() {
        this.submitted = true;

        if (!this.assignPropertyForm.valid) {
            return;
        }

        this._propertyService.addPropertyToEmployee(this.assignPropertyForm.value, this.property.property_ID)
            .then(() => this.activeModal.close('close'));
    }
}
