import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, RegistrationError, Token } from '@capacitor/push-notifications';
import firebase from 'firebase/compat';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services/authentication.service';
import { getOS } from '../functions';
import MessagePayload = firebase.messaging.MessagePayload;

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    constructor(
        private _http: HttpClient,
        private _authService: AuthenticationService,
    ) { }

    public notificationSwalOptions: SweetAlertOptions = {
        toast: true,
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
        icon: 'success',
        position: 'bottom-start',
        showClass: {
            icon: 'mr-2'
        }
    };

    public initialize(): void {
        isSupported().then((supported: boolean) => {
            if (supported && Capacitor.getPlatform() === 'web' && (getOS() === 'macos' || getOS() === 'windows' || getOS() === 'linux')) {
                this._requestPermission();
                this._listen();
            }
        });

        if (Capacitor.isNativePlatform()) {
            this._registerPush();
        }
    }

    // Desktop Push Notifications
    private _requestPermission(): void {
        const messaging = getMessaging();
        getToken(messaging,
            {vapidKey: environment.firebase.vapidKey}).then(
            (currentToken) => {
                if (currentToken) {
                    this._checkFirebaseToken(currentToken);
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
    }

    private _listen(): void {
        const messaging = getMessaging();
        onMessage(messaging, (message: MessagePayload) => {
            console.log('Message received. ', message);
            void Swal.fire({
                ...this.notificationSwalOptions,
                title: message.notification.title,
                text: message.notification.body,
            });
        });
    }

    private _checkFirebaseToken(token: string): void {
        this._http.post<any>(`/api/company-settings/notifications/firebase-token`, {fcm_token: token})
            .subscribe();
    }

    // Capacitor Push Notifications
    private _registerPush(): void {
        PushNotifications.requestPermissions().then(result => {
            console.log('PushNotifications.requestPermissions', result);
            if (result.receive === 'granted') {
                PushNotifications.register()
                    .then((res) => { console.log(res); },
                        (err) => { console.log(err); });
            } else {
                console.log('User denied permissions');
            }
        });

        void PushNotifications.addListener('registration', (token: Token) => {
            this._checkFirebaseToken(token.value);
        });

        void PushNotifications.addListener('registrationError', (err: RegistrationError) => {
            console.log('Registration error: ', err.error);
        });

        void PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
            console.log('Push notification received: ', notification);
        });

        void PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
            console.log('Push notification clicked', notification.actionId, notification.inputValue);
        });
    }
}
