import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { ListResponse } from '../../../../api';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { UserService } from '../../../../setting/services/user.service';

interface EmployeeWithCompanyGroupNames extends Employee {
    companyGroupNames: string;
}

@Component({
    selector: 'app-employee-contact-list',
    templateUrl: './employee-contact-list.component.html',
    styleUrls: ['./employee-contact-list.component.css']
})
export class EmployeeContactListComponent extends FatherOfListComponent<Employee> implements OnInit {

    public sort: Sort<Employee> = {
        column: 'fullname',
        direction: SORT_ASC
    };

    public companyGroups$: Observable<any>;
    public companyBranches$: Observable<any>;
    public availableWidgets$: Observable<AvailableWidgets>;

    private _fetchEmployees$ = new ReplaySubject<void>(1);

    public constructor(
        private _employeeService: EmployeeService,
        private _companyService: CompanyService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this._rows$ = this._fetchEmployees$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._employeeService.getEmployeesList(params, 'COMMON', {'contact-list': true})),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this.availableWidgets$ = this._companyService.availableWidgets$;

        this._init();

        this.useLocalStorage(null, 'employeeContactList');

        this._fetchListData();
    }

    public trackByFn(index: number, employee: Employee): number {
        return employee.employee_ID;
    }

    protected _fetchListData(): void {
        this._fetchEmployees$.next();
    }

    protected _setupList(response: ListResponse<Employee>): Array<EmployeeWithCompanyGroupNames> {
        return this._extendCompanyGroupNames(super._setupList(response));
    }

    private _extendCompanyGroupNames(employees: Array<Employee>): Array<EmployeeWithCompanyGroupNames> {
        const extendedEmployees: Array<EmployeeWithCompanyGroupNames> = [];

        let companyGroupNames = [];

        for (let i = 0, max = employees.length; i < max; i++) {
            const employee = employees[i];

            companyGroupNames = employee.owned_company_groups?.map(group => `👑 ${group.name}`) || [];

            if (employee.company_group) {
                companyGroupNames.push(employee.company_group.name);
            }

            extendedEmployees.push({
                ...employee,
                companyGroupNames: companyGroupNames.join(', ')
            });
        }

        return extendedEmployees;
    }
}
