import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ID } from '@datorama/akita';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CreateEditBranchComponent } from '../../../../setting/components/partials/create-edit-branch/create-edit-branch.component';
import { CompanyBranch } from '../../../../setting/models/CompanyBranch';
import { CompanyPosition } from '../../../../setting/models/CompanyPosition';
import { CompanyPositionService } from '../../../../setting/services/company-position.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { SummernoteHelper } from '../../../../shared/common/SummernoteHelper';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { Employee } from '../../../models/Employee';
import { EmployeePosition } from '../../../models/EmployeePosition';
import { EmployeeService } from '../../../services/employee.service';

@Component({
    selector: 'app-position-form',
    templateUrl: './position-form.component.html',
    styleUrls: ['./position-form.component.css']
})
export class PositionFormComponent implements OnInit, OnDestroy {
    @ViewChild('positionSelect', {static: true}) positionSelect: NgSelectComponent;

    @Input() public submitted = false;
    @Input() public employeePosition: EmployeePosition;
    @Input() public employee: Employee;
    @Input() public employeeID: number;

    public canEdit = false;
    public form: UntypedFormGroup;
    public mainBranchID: ID;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public companyBranches$: Observable<CompanyBranch[]>;
    public companyPositions$: Observable<CompanyPosition[]>;
    public showSetMainBranch = false;
    public oddEvenWeek = false;
    public selectedCompanyPosition = {company_position_ID: 0, label: this._translateService.instant('employees_positions.create_new_position')} as CompanyPosition;
    public workDays = [
        {value: 1, label: this._translateService.instant('dates.Monday')},
        {value: 2, label: this._translateService.instant('dates.Tuesday')},
        {value: 3, label: this._translateService.instant('dates.Wednesday')},
        {value: 4, label: this._translateService.instant('dates.Thursday')},
        {value: 5, label: this._translateService.instant('dates.Friday')},
        {value: 6, label: this._translateService.instant('dates.Saturday')},
        {value: 7, label: this._translateService.instant('dates.Sunday')},
    ];
    public companyPositionFormGroup = this._fb.group({
        name: ['', Validators.required],
        job_description: [''],
        description: [''],
        type: ['', Validators.required],
        salary_from: [''],
        salary_to: [''],
        salary_default: [''],
    });

    public positionsTypes = [
        {value: 'HPP', label: this._translateService.instant('employees_positions.HPP')},
        {value: 'DPC', label: this._translateService.instant('employees_positions.DPC')},
        {value: 'DPP', label: this._translateService.instant('employees_positions.DPP')},
        {value: 'ICO', label: this._translateService.instant('employees_positions.ICO')},
        {value: 'INTERN', label: this._translateService.instant('employees_positions.INTERN')},
        {value: 'EXTERNIST', label: this._translateService.instant('employees_positions.EXTERNIST')},
    ];

    private _formSubscription: Subscription;

    constructor(
        public summernoteHelper: SummernoteHelper,
        public activeModal: NgbActiveModal,
        public fpHelper: FlatpickrHelper,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _translateService: TranslateService,
        private _modalService: NgbModal,
        private _employeeService: EmployeeService,
        private _authService: AuthenticationService,
        private _companyPositionService: CompanyPositionService,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public ngOnDestroy(): void {
        this._formSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.refreshCompanyBranches();
        this.companyPositions$ = this._companyPositionService.getAllCompanyPositions()
            .pipe(map(companyPositions => {
                companyPositions = companyPositions.map(companyPosition => {
                    return {
                        ...companyPosition,
                        label: companyPosition.name + ' (' + this._translateService.instant('employees_positions.' + companyPosition.type) + ')',
                    };
                });
                companyPositions.push({company_position_ID: 0, label: this._translateService.instant('employees_positions.create_new_position')} as CompanyPosition);
                return companyPositions;
            }));

        this.form = this._fb.group({
            from: ['', Validators.required],
            indefinitely: [false],
            is_uneven: [false],
            to: [''],
            hours_per_week: [40],
            no_test_time: [false],
            test_time_end: [''],
            flexible_work_hours: [false],
            shift_start: [''],
            shift_end: [''],
            company_branch_ID: [''],
            place_of_work: [''],
            position_description: [''],
            company_position: this.companyPositionFormGroup,
            working_days: this._fb.group({
                1: 8,
                2: 8,
                3: 8,
                4: 8,
                5: 8,
                6: 0,
                7: 0
            })
        });

        this._formSubscription = this.form.valueChanges
            .subscribe(values => {
                if (
                    values.no_test_time === true &&
                    this.form.controls.test_time_end.enabled
                ) {
                    this.form.controls.test_time_end.disable();

                    this.form.controls.test_time_end.setValue('', {emitEvent: false});
                } else if (
                    values.no_test_time === false &&
                    this.form.controls.test_time_end.disabled
                ) {
                    this.form.controls.test_time_end.enable();
                }
            });

        this.canEdit = this._authService.checkPermission(['employeePosition.edit', 'employeePosition.editStructure']) || this.employee?.permissions?.editPosition;

        if (this.employeePosition) {
            this.getPositionAndPrefillForm();
        } else {
            this.form.patchValue({
                company_branch_ID: this.employee?.company_branch_ID,
                type: this.positionsTypes[0].value
            });
        }

        if (!this.canEdit) {
            this.form.disable();
        }
    }

    public getPositionAndPrefillForm(): void {
        if (this.employeePosition.working_days['8'] !== undefined) {
            this.oddEvenWeekChanged();
        }
        this.form.patchValue(this.employeePosition);

        if (this.employeePosition?.company_position?.company_position_ID) {
            const pos = this.employeePosition.company_position;
            pos.label = pos.name + ' (' + this._translateService.instant('employees_positions.' + pos.type) + ')';
            this.selectedCompanyPosition = pos;
            this.onCompanyPositionChange(this.selectedCompanyPosition);
            this.positionSelect.readonly = true;
        }

        if (!this.employeePosition.company_branch_ID) {
            this.form.patchValue({
                company_branch_ID: 0
            });
        }
    }

    public openBranchEditModal(): void {
        const modalRef = this._modalService.open(CreateEditBranchComponent, {size: 'lg', centered: true});

        modalRef.result
            .then(
                () => {
                    this.refreshCompanyBranches();
                },
                () => { }
            );
    }

    public onBranchChange(selectedBranchID: ID): void {
        if (selectedBranchID !== this.mainBranchID && selectedBranchID !== 0 && selectedBranchID !== null) {
            this.showSetMainBranch = true;
            this.form.addControl('set_as_main_branch', new FormControl(false));
        } else {
            this.showSetMainBranch = false;
            this.form.removeControl('set_as_main_branch');
        }
    }

    public refreshCompanyBranches(): void {
        this.companyBranches$ = this._employeeService.getAllCompanyBranches('minimal', this.employeeID)
            .pipe(map(companyBranches => {
                companyBranches.forEach(companyBranch => {
                    if (companyBranch.is_main_branch) {
                        this.mainBranchID = companyBranch.company_branch_ID;
                        companyBranch.name = `${companyBranch.name} (${this._translateService.instant('employees_positions.is_main_branch_indicator')})`;
                    }
                });
                if (this.employeePosition) {
                    this.onBranchChange(this.employeePosition.company_branch_ID);
                }

                return companyBranches;
            }));
    }

    public onCompanyPositionChange($event: CompanyPosition): void {
        if ($event.company_position_ID === 0) {
            this.form.controls.company_position.enable();
            this.form.controls.company_position.reset();
            this.form.removeControl('company_position_ID');
        } else {
            this.form.controls.company_position.disable();
            this.form.addControl('company_position_ID', new FormControl(null));
            this.form.patchValue({
                company_position_ID: $event.company_position_ID,
                company_position: $event
            });
        }
    }

    public compareFn(c1: CompanyPosition, c2: CompanyPosition): boolean {
        return c1 && c2 ? c1.company_position_ID === c2.company_position_ID : c1 === c2;
    }

    public oddEvenWeekChanged(): void {
        this.oddEvenWeek = !this.oddEvenWeek;
        const lastValue = this.form.value.working_days;
        this.form.removeControl('working_days');
        if (this.oddEvenWeek) {
            this.form.addControl('working_days', this._fb.group({
                1: 8,
                2: 8,
                3: 8,
                4: 8,
                5: 8,
                6: 0,
                7: 0,
                8: 8,
                9: 8,
                10: 8,
                11: 8,
                12: 8,
                13: 0,
                14: 0
            }));
        } else {
            this.form.addControl('working_days', this._fb.group({
                1: 8,
                2: 8,
                3: 8,
                4: 8,
                5: 8,
                6: 0,
                7: 0,
            }));
        }

        this.form.patchValue({working_days: lastValue});
    }
}
