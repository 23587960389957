import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OrgChart } from 'd3-org-chart';
import { Observable, ReplaySubject, Subscription } from 'rxjs';

@Component({
    selector: 'app-d3-org-chart',
    templateUrl: './d3-org-chart.component.html',
    styleUrls: ['./d3-org-chart.component.css']
})
export class D3OrgChartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @ViewChild('chartContainer') chartContainer: ElementRef;

    @Input() data: any[];
    @Input() exportChart$: ReplaySubject<void>;

    public chart: OrgChart<any>;
    public nodeData = [];
    public canEdit$: Observable<boolean>;

    private _subscription: Subscription;

    constructor(
        private _router: Router,
    ) {}

    public ngOnInit(): void {}

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngAfterViewInit(): void {
        if (!this.chart) {
            this.chart = new OrgChart();
        }
        this.updateChart();

        this._subscription = this.exportChart$.subscribe(() => {
            this.chart.exportImg({save: true, full: true});
        });
    }

    public ngOnChanges(): void {
        this.updateChart();
    }

    public updateChart(): void {
        if (!this.data) {
            return;
        }
        if (!this.chart) {
            return;
        }

        this.chart
            .container(this.chartContainer.nativeElement)
            .data(this.data)
            .nodeWidth(() => 250)
            .initialZoom(0.7)
            .nodeHeight(() => 155)
            .childrenMargin(() => 40)
            .compactMarginBetween(() => 15)
            .compactMarginPair(() => 80)
            .nodeContent((d: any, i, arr, state) => {
                this.nodeData[d.id] = d.data;
                return `
        <div id="employee-${d.id}" style="padding-top:30px;background-color:none;margin-left:1px;height:${
                    d.height
                }px;border-radius:2px;overflow:visible">
          <div style="height:${
                    d.height - 32
                }px;padding-top:0px;background-color:white;border:1px solid lightgray;">
              <div class="avatar-wrapper" style="margin-top:-30px; margin-left:${d.width / 2 - 30}px; border-radius:60px; width:50px; height:50px; overflow: hidden;
                    line-height: 48px; text-align: center; z-index: 9; background: white;
                    ">
                 ` + (
                    d.data.id === '0' && d.data.imageUrl == null ? `<div style="width: 50px; height: 50px"></div>` : `
                  <img src=" ${
                        d.data.imageUrl ? d.data.imageUrl : '/assets/images/avatars/Avatar_male_01.png'
                    }" style="max-width: 100%; max-height: 100%; line-height: 1; vertical-align: middle; background: #fff;" alt="avatar"/>
                  `
                ) + `
              </div>
            <div style="margin-top:-30px;background-color: ${d.data.is_self ? '#6979ed' : d.data.color};height:10px;width:${d.width - 2}px;border-radius:1px"></div>

            <div style="padding:20px; padding-top:35px;text-align:center">
                <div style="color:#111672;font-size:16px;font-weight:bold; width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${(d.data.archived ? '🗃 ' + d.data.name : d.data.name)}</div>
                <div style="color:#404040;font-size:16px;margin-top:4px">${d.data.title ?? ' '}</div>
                <div style="color:#606060;font-size:14px;font-style:italic;margin-top:4px">${d.data.group ?? ''}</div>
            </div>
          </div>
      </div>
      `;
            })
            .onNodeClick((nodeID: any) => {
                const clickedNode = this.nodeData[nodeID];
                if (clickedNode?.permissions?.view) {
                    void this._router.navigateByUrl('/employee/' + clickedNode.employee_ID + '/detail/overview');
                }
            })
            .render()
            .expandAll();
    }
}
