import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ID } from '@datorama/akita';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Import } from 'src/app/import/state/import.model';
import { ImportService } from 'src/app/import/state/import.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { UserService } from '../../../../setting/services/user.service';

@Component({
    selector: 'app-import-list',
    templateUrl: './import-list.component.html',
    styleUrls: ['./import-list.component.css']
})
export class ImportListComponent extends FatherOfListComponent<Import> implements OnInit {
    public canCreate$: Observable<boolean>;
    public canView$: Observable<boolean>;
    public sort: Sort<Import> = {
        column: 'created_at',
        direction: SORT_ASC
    };

    private _fetchImports$ = new ReplaySubject<void>(1);

    public constructor(
        private _importService: ImportService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this.canCreate$ = this._authService.hasPermissionTo('import.create')
            .pipe(map(permission => permission.can));

        this.canView$ = this._authService.hasPermissionTo('import.view')
            .pipe(map(permission => permission.can));

        this._rows$ = this._fetchImports$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._importService.getImportsList(params)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public trackByFn(_index: number, importData: Import): ID {
        return importData.import_ID;
    }

    protected _fetchListData(): void {
        this._fetchImports$.next();
    }
}
