import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ID } from '@datorama/akita';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Onboarding } from 'src/app/onboarding/onboarding/state/onboarding.model';
import { OnboardingService } from 'src/app/onboarding/onboarding/state/onboarding.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { UserService } from '../../../../setting/services/user.service';
import { CreateEditOnboardingModalComponent } from '../../partials/create-edit-onboarding-modal/create-edit-onboarding-modal.component';

@Component({
    selector: 'app-onboarding-list',
    templateUrl: './onboarding-list.component.html',
    styleUrls: ['./onboarding-list.component.css']
})
export class OnboardingListComponent extends FatherOfListComponent<Onboarding> implements OnInit {
    public canCreate$: Observable<boolean>;

    public sort: Sort<Onboarding> = {
        column: 'name',
        direction: SORT_ASC
    };

    private _fetchOnboardings$ = new ReplaySubject<void>(1);

    public constructor(
        private _modalService: NgbModal,
        private _onboardingService: OnboardingService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this.canCreate$ = this._authService.hasPermissionTo('onboarding.create')
            .pipe(map(permission => permission.can));

        this._rows$ = this._fetchOnboardings$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._onboardingService.getOnboardingsList(params)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public openEditModal(onboarding?: Onboarding): void {
        const modalRef = this._modalService.open(CreateEditOnboardingModalComponent, {centered: true});

        modalRef.componentInstance.onboarding = onboarding;

        modalRef.result
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    public trackByFn(index: number, onboarding: Onboarding): ID {
        return onboarding.onboarding_ID;
    }

    protected _fetchListData(): void {
        this._fetchOnboardings$.next();
    }
}
