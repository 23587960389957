<div *ngIf="employeeOnboarding$ | async as employeeOnboarding; else loadingTemplate" class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span>{{ employeeOnboarding.name }}</span>
    </p>

    <button (click)="activeModal.dismiss()" aria-label="Close" class="close">
      <span aria-hidden="true" class="font-24 text-muted">
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <div *ngIf="canEdit" class="mb-4">
              <form #fCreate="ngForm" (keydown.enter)="$event.preventDefault()">
                <div class="d-inline-flex w-100">
                  <input class="form-control mr-2"
                         name="name"
                         ngModel
                         required
                         placeholder="{{ 'onboardings_tasks.action_create_task' | translate }}"
                         type="text">
                  <button
                    (click)="onSubmit(fCreate.form, employeeOnboarding, null)"
                    [createButton]="'onboarding.action_add_task' | translate"
                  ></button>
                </div>
                  <div *ngIf="submitted && fCreate.controls?.name?.errors">
                      <p *ngIf="fCreate.controls?.name?.errors.required" class="text-danger validation-text mb-0">
                          {{ 'angular_validation.field' | translate }}
                          {{'angular_validation.required' | translate }}
                      </p>
                  </div>
              </form>
            </div>

            <ng-container *ngFor="let task of employeeOnboarding.tasks; let i = index;">
              <hr class="mt-2 mb-2"/>

              <div class="row align-items-center">
                <div [ngClass]="{'col-6': task.assignee?.fullname || task.deadline, 'col-11': !task.assignee?.fullname && !task.deadline}">
                  <div class="d-flex align-items-center justify-content-between">
                    <div>
                      <button [disabled]="!canEdit && loggedUser?.user_ID !== task.assignee_ID" (click)="completeTask(task, employeeOnboarding)" *ngIf="!task.completed_at" class="btn btn-link btn-icon text-muted pr-1 pl-1">
                        <i class="mdi mdi-checkbox-blank-circle-outline"></i>
                      </button>

                      <button [disabled]="!canEdit && loggedUser?.user_ID !== task.assignee_ID" (click)="unCompleteTask(task, employeeOnboarding)" *ngIf="task.completed_at" class="btn btn-link btn-icon text-green pr-1 pl-1">
                        <i class="mdi mdi-check-circle-outline"></i>
                      </button>
                    </div>
                    <div class="flex-1">
                        <span [style.text-decoration]="task.completed_at  ? 'line-through' : null">{{ task.name }}</span>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="task.assignee?.fullname || task.deadline">
                  <div class="col-3">{{ task.assignee?.fullname }}</div>
                  <div class="col-2">{{ task.deadline | date:'dd.MM.yyyy' }}</div>
                </ng-container>

                <div class="col-1 pl-0 text-right">
                    <button
                      (click)="isCollapsed[i] = !isCollapsed[i]"
                      *ngIf="canEdit"
                      [attr.aria-expanded]="!isCollapsed[i]"
                      [updateButton]="'onboarding.action_edit_task' | translate"
                      aria-controls="collapse"
                      type="submit"
                      >
                    </button>
                </div>
              </div>



              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="!isCollapsed[i]"
              >
                <form #fEdit="ngForm" (keydown.enter)="$event.preventDefault()">
                  <div class="row mt-2">
                    <div class="col-12">
                      <label class="control-label" for="onboarding_task_name">
                        <span>{{ 'onboardings_tasks.name' | translate }}</span>
                      </label>
                      <input [ngModel]="task.name"
                             id="onboarding_task_name"
                             class="form-control x-1"
                             name="name"
                             required
                             type="text" />
                        <div *ngIf="submittedTask && fEdit.controls?.name?.errors">
                            <p *ngIf="fEdit.controls?.name?.errors.required" class="text-danger validation-text mb-0">
                                {{ 'angular_validation.field' | translate }}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                  </div>
                  <div class="row mt-2 mb-2">
                    <div class="col-6">
                      <label class="control-label" for="onboarding_task_assignee">
                        <span>{{ 'onboardings_tasks.assignee' | translate }}</span>
                      </label>
                        <ng-select id="onboarding_task_assignee" [closeOnSelect]="false" [items]="availableUsers" [multiple]="false" [ngModel]="task.assignee_ID" bindLabel="fullname"
                                  bindValue="user_ID" class="custom" name="assignee_ID"></ng-select>
                      </div>
                      <div class="col-6">
                        <label class="control-label" for="onboarding_task_deadline">
                          <span>{{ 'onboardings_tasks.deadline' | translate }}</span>
                        </label>
                        <input
                            id="onboarding_task_deadline"
                          [locale]="locale$ | async"
                          [ngModel]="task.deadline"
                          allowInput="true"
                          altFormat="d.m.Y"
                          altInput="true"
                          class="form-control"
                          dateFormat="Y-m-d"
                          mwlFlatpickr
                          name="deadline"
                          type="text"
                        >
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-right">
                      <u (click)="deleteTask(employeeOnboarding, task)" *ngIf="canEdit" class="text-muted mr-2">
                        {{ 'onboardings_tasks.action_delete' | translate }}
                      </u>
                      <button (click)="saveTask(employeeOnboarding, task, fEdit.form)" *ngIf="canEdit" class="btn btn-success"><span>{{ 'onboardings_tasks.action_edit' | translate }}</span></button>
                    </div>
                  </div>
                </form>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6 text-left"></div>
      <div class="col-6 my-auto text-right">
        <u (click)="unAssignEmployeeFromOnboarding(employeeOnboarding)" *ngIf="canEdit" class="text-muted">
          {{ 'employees_onboardings.action_delete' | translate }}
        </u>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
    <app-modal-loader></app-modal-loader>
</ng-template>
