import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { browserTracingIntegration, init } from '@sentry/angular-ivy';
import Smartlook from 'smartlook-client';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
    init({
        dsn: 'https://9ae8cfdd1dfb4591a516397d6164e5be@o4504570118275072.ingest.sentry.io/4504570120830977',
        tracesSampleRate: 0.5,
        integrations: [browserTracingIntegration()],
    });
    Smartlook.init('91a983b5eaed6b860624a01b161a9eed70cf9c5a', {region: 'eu'});
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
