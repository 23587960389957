import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { EmployeeDatetimeType } from 'src/app/setting/models/EmployeeDatetimeType';
import { DatetimesTypesService } from 'src/app/setting/services/datetimes-types.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { UserService } from '../../../services/user.service';
import { CreateEditDatetimeTypeComponent } from '../../partials/create-edit-datetime-type/create-edit-datetime-type.component';
import { CreateMultipleAttendanceComponent } from '../../partials/create-multiple-attendance/create-multiple-attendance.component';

@Component({
    selector: 'app-datetime-types-list',
    templateUrl: './datetime-types-list.component.html',
    styleUrls: ['./datetime-types-list.component.css']
})
export class DatetimeTypesListComponent extends FatherOfListComponent<EmployeeDatetimeType> implements OnInit {
    public canCreate$: Observable<boolean>;

    public sort: Sort<EmployeeDatetimeType> = {
        column: 'name',
        direction: SORT_ASC
    };

    private _fetchDateTimeTypes$ = new ReplaySubject<void>(1);

    public constructor(
        private _datetimeTypesService: DatetimesTypesService,
        private _modalService: NgbModal,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this.canCreate$ = this._authService.hasPermissionTo('employeeDatetimeType.create')
            .pipe(map(permission => permission.can));

        this._rows$ = this._fetchDateTimeTypes$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._datetimeTypesService.getDatetimeTypesList(params)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public openEditModal(datetimeType?: EmployeeDatetimeType): void {
        const modalRef = this._modalService.open(CreateEditDatetimeTypeComponent, {centered: true});

        if (datetimeType) {
            modalRef.componentInstance.datetimeType = datetimeType;
        }

        modalRef.result
            .then(
                () => {
                    this._fetchDateTimeTypes$.next();
                },
                () => { }
            );
    }

    public openMultipleCreationModal(datetimeType?: EmployeeDatetimeType): void {
        const modalRef = this._modalService.open(CreateMultipleAttendanceComponent, {centered: true});
        modalRef.componentInstance.datetimeType = datetimeType;
    }

    public trackByFn(index: number, dateTimeType: EmployeeDatetimeType): number | string {
        return dateTimeType.employee_datetime_type_ID;
    }

    protected _fetchListData(): void {
        this._fetchDateTimeTypes$.next();
    }

    public getStripeColor(color: string) {
        // Convert hex color string to RGB
        const r = parseInt(color.substring(1, 3), 16);
        const g = parseInt(color.substring(3, 5), 16);
        const b = parseInt(color.substring(5, 7), 16);

        // Calculate color brightness
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // Return true if color is light, false if color is dark
        return brightness > 128 ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)';
    }
}
