import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Employee } from 'src/app/employee/models/Employee';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { UserService } from '../../../../setting/services/user.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { BenefitService } from '../../../benefit/state/benefit.service';

interface EmployeeFromBenefit {
    avatar: Employee['avatar'];
    employee_ID: Employee['employee_ID'];
    employee_benefit_ID: number;
    fullname: Employee['fullname'];
    handover_date: string | null;
    note: string;
    removal_date: string | null;
}

@Component({
    selector: 'app-benefit-employee-list',
    templateUrl: './benefit-employee-list.component.html',
    styleUrls: ['./benefit-employee-list.component.css']
})
export class BenefitEmployeeListComponent extends FatherOfListComponent<Employee> implements OnInit, OnDestroy {
    @ViewChild('filterFormOptions', {static: false})
    public filterFormOptions: TemplateRef<ElementRef>;

    @Input() public benefitID: number;

    public allEmployees: Employee[];
    public submitted = false;
    public selectedEmployee$: Observable<Employee | null>;
    public benefitAssignmentForm: UntypedFormGroup;
    public filterForm: UntypedFormGroup;
    public sort: Sort<Employee> = {
        column: 'fullname',
        direction: SORT_ASC
    };
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;

    private _fetchEmployees$ = new ReplaySubject<void>(1);
    private _selectedEmployee$ = new BehaviorSubject<Employee | null>(null);
    private _filterFormModalRef: NgbModalRef;
    private _benefitAssignmentModalRef: NgbModalRef;
    private _subscription: Subscription;

    public constructor(
        public fpHelper: FlatpickrHelper,
        private _benefitService: BenefitService,
        private _modalService: NgbModal,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _employeeService: EmployeeService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.benefitAssignmentForm.controls;
    }

    public getCount(field: string) {
        return this.filterForm.get(field).value?.length;
    }

    public ngOnInit(): void {
        this._init();
        this._fetchListData();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this._subscription?.unsubscribe();
    }

    public onSubmitFilter(): void {
        this._filterFormModalRef?.close();

        this._fetchListData();
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public trackByFn(index: number, employee: Employee): number {
        return employee.employee_ID;
    }

    public openAssignModal(content: any, employee: EmployeeFromBenefit): void {
        this._benefitAssignmentModalRef = this._modalService.open(content, {centered: true});

        this._selectedEmployee$.next(null);

        if (employee) {
            this.benefitAssignmentForm.patchValue({
                employee_ID: employee.employee_ID,
                employee_benefit_ID: employee.employee_benefit_ID,
                handover_date: employee.handover_date,
                removal_date: employee.removal_date,
                note: employee.note
            });

            this._selectedEmployee$.next(this.allEmployees.find(e => e.employee_ID === employee.employee_ID));
        }

        this._benefitAssignmentModalRef.result
            .then(
                () => { },
                () => { }
            )
            .finally(() => {
                this.benefitAssignmentForm.clearValidators();
                this.benefitAssignmentForm.reset();

                this.submitted = false;
            });
    }

    public unassignEmployeeFromBenefit(employee: EmployeeFromBenefit): void {
        this._benefitService.removeEmployeeRelation(employee.employee_ID, this.benefitID, employee.employee_benefit_ID)
            .then(() => this._fetchEmployees$.next());
    }

    public onSubmitAssign(): void {
        this.submitted = true;

        if (!this.benefitAssignmentForm.valid) {
            return;
        }

        const value = this.benefitAssignmentForm.value;

        this._benefitService.assignBenefitToEmployee(value, value.employee_ID, this.benefitID)
            .subscribe(() => {
                this._benefitAssignmentModalRef.dismiss();

                this._fetchEmployees$.next();
            });
    }

    protected _init(): void {
        this.filterForm = this._fb.group({
            active_only: [true],
        });

        this._subscription = this._employeeService.getAllEmployees(false, 'select')
            .subscribe(employees => this.allEmployees = employees);

        this._rows$ = this._fetchEmployees$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._benefitService.getEmployeesListByBenefitID(this.benefitID, params, this.filterForm.value)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this.benefitAssignmentForm = this._fb.group({
            employee_ID: [null, Validators.required],
            // needs to be here because of update
            employee_benefit_ID: [null],
            handover_date: ['', Validators.required],
            removal_date: [''],
            note: ['']
        });

        super._init();
    }

    protected _fetchListData(): void {
        this._fetchEmployees$.next();
    }
}
