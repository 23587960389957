import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentTemplate } from 'src/app/setting/models/DocumentTemplate';
import { DocumentTemplateService } from 'src/app/setting/services/document-template.service';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AvailableFeatures } from '../../../../employee/models/AvailableFeatures';
import { DocumentTemplateVocabulary } from '../../../models/DocumentTemplateVocabulary';
import { CreateEditDocumentTemplateComponent } from '../../partials/create-edit-document-template/create-edit-document-template.component';

@Component({
    selector: 'app-document-template-detail',
    templateUrl: './document-template-detail.component.html',
    styleUrls: ['./document-template-detail.component.css']
})
export class DocumentTemplateDetailComponent implements OnInit, OnDestroy {
    @ViewChild('vocabularyModal', {static: false})
    public vocabularyModal: TemplateRef<ElementRef>;

    public availableFeatures$: Observable<AvailableFeatures>;
    public canEditTemplate: boolean;
    public canDeleteTemplate: boolean;
    public showDropzone = false;
    public showHtmlButton = false;
    public documentTemplate$: Observable<DocumentTemplate>;
    public file$: Observable<any[]>;
    public key: string;
    public $vocabulary: Observable<Array<DocumentTemplateVocabulary>>;

    private _notificationSubscription: Subscription;

    public constructor(
        private _documentTeplateService: DocumentTemplateService,
        private _modalService: NgbModal,
        private _authService: AuthenticationService,
        private _route: ActivatedRoute,
        private _subscriptionService: SubscriptionService,
    ) { }

    public ngOnInit(): void {
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;
        this._route.params.subscribe(params => {
            this.key = params.key;
            this.refreshData();
        });

        this.$vocabulary = this._documentTeplateService.getVocabulary();

        this.canEditTemplate = this._authService.checkPermission('documentTemplate.edit');
        this.canDeleteTemplate = this._authService.checkPermission('documentTemplate.delete');
    }

    public ngOnDestroy(): void {
        this._notificationSubscription?.unsubscribe();
    }

    public refreshData(): void {
        this.documentTemplate$ = this._documentTeplateService.getTemplateByKey(this.key)
            .pipe(map(documentTemplate => {
                if (documentTemplate.extension === 'html') {
                    this.showHtmlButton = true;
                    if (!documentTemplate.templateBase64) {
                        this.showDropzone = true;
                    }
                }
                if (documentTemplate.extension === 'docx' || documentTemplate.extension === 'pdf') {
                    this.showDropzone = true;
                    this.file$ = of([{fileBase64: documentTemplate.templateBase64, name: 'Nahraná šablona', hash: documentTemplate.key, download_url: documentTemplate.download_url}]);
                } else {
                    this.file$ = of([]);
                }
                return documentTemplate;
            }));
    }

    public openEditModal(documentTemplate: DocumentTemplate): void {
        const modalRef = this._modalService.open(CreateEditDocumentTemplateComponent, {centered: true});

        modalRef.componentInstance.documentTemplate = documentTemplate;

        modalRef.result
            .then(
                () => this.refreshData(),
                () => { }
            );
    }

    public openVocabularyModal(): void {
        this._modalService.open(this.vocabularyModal, {centered: true, size: 'lg'});
    }

    public sendNotification(documentTemplateID: number): void {
        this._notificationSubscription = this._documentTeplateService.sendSignatureNotifications(documentTemplateID).subscribe();
    }

    public getTypeOfVariable(variable: any): string {
        return typeof variable;
    }
}
