import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject } from 'rxjs';
import { first, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { File } from 'src/app/employee/models/File';
import { DocumentService } from 'src/app/employee/services/document.service';
import { Property, Thumbnail } from 'src/app/property/models/Property';
import { PropertyCategory } from 'src/app/property/models/PropertyCategory';
import { PropertyService } from 'src/app/property/services/property.service';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { FileService } from 'src/app/shared/services/file.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { CreateDocumentByTemplateModalComponent } from '../../../../employee/components/partials/create-document-by-template-modal/create-document-by-template-modal.component';
import { AvailableFeatures } from '../../../../employee/models/AvailableFeatures';
import { UserService } from '../../../../setting/services/user.service';
import { MultiFilesPreviewModalComponent } from '../../../../shared/components/partials/multi-files-preview-modal/multi-files-preview-modal.component';
import { AssignPropertyComponent } from '../assign-property/assign-property.component';
import { CreateEditPropertyComponent } from '../create-edit-property/create-edit-property.component';

@Component({
    selector: 'app-property-overview',
    templateUrl: './property-overview.component.html',
    styleUrls: ['./property-overview.component.css']
})
export class PropertyOverviewComponent extends FatherOfListComponent<Employee> implements OnInit {
    public canDelete$: Observable<boolean>;
    public categories$: Observable<Array<PropertyCategory>>;
    public availableFeatures$: Observable<AvailableFeatures>;
    public employeePropertyFiles$: Observable<Array<File>>;
    public employeePropertyId: number;
    public property$: Observable<Property>;
    public propertyFiles$: Observable<Array<File>>;
    public selectedEmployeeProperty: Employee;
    public sort: Sort<Employee> = {
        column: 'name',
        direction: SORT_ASC
    };
    public thumbnail: Thumbnail | null;

    private _fetchEmployees$ = new ReplaySubject<void>(1);
    private _fetchProperty$ = new ReplaySubject<void>(1);
    private _fetchPropertyFiles$ = new ReplaySubject<void>(1);
    private _propertyId$: Observable<number>;

    public constructor(
        public documentService: DocumentService,
        private _documentService: DocumentService,
        private _fileService: FileService,
        private _modalService: NgbModal,
        private _propertyService: PropertyService,
        private _route: ActivatedRoute,
        private _subscriptionService: SubscriptionService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;

        this.canDelete$ = this._authService.hasPermissionTo('property.edit')
            .pipe(map(permission => permission.can));

        this._propertyId$ = this._route.params
            .pipe(map(params => parseInt(params.propertyID, 10)));

        this.categories$ = this._propertyService.getAllPropertyCategories();

        this._rows$ = this._fetchEmployees$
            .pipe(
                tap(() => this._loading$.next(true)),
                withLatestFrom(this._propertyId$),
                switchMap(([noop, propertyId]) => this._propertyService.getAllPropertyEmployeesByPropertyID(propertyId)),
                tap(employees => {
                    if (this.selectedEmployeeProperty) {
                        const selectedEmployee = employees.find(emp => emp.employee_property_ID === this.employeePropertyId);

                        if (selectedEmployee) {
                            this.employeePropertyFiles$ = of(selectedEmployee.files);
                        }
                    }
                }),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();

        this.property$ = this._fetchProperty$
            .pipe(
                withLatestFrom(this._propertyId$),
                switchMap(([noop, propertyId]) => this._propertyService.getPropertyByID(propertyId)),
                tap(property => {
                    this.thumbnail = property?.thumbnail || null;
                }),
                shareReplay()
            );

        this.propertyFiles$ = this._fetchPropertyFiles$
            .pipe(
                withLatestFrom(this._propertyId$),
                switchMap(([noop, propertyId]) => this._propertyService.getAllFilesByPropertyID(propertyId))
            );

        this.loadProperty();
        this.loadPropertyFiles();
    }

    public deleteFile(fileID: number, documentID: number): void {
        this.property$
            .pipe(first())
            .subscribe(property => {
                let promise = null;

                if (documentID) {
                    promise = this._documentService.deleteDocument(documentID);
                } else {
                    promise = this._fileService.deleteFileByID(fileID, property, 'PROPERTY');
                }

                promise.then(() => {
                    this.loadPropertyFiles();

                    this.loadProperty();
                });
            });
    }

    public deleteProperty(content: any): void {
        this._modalService.open(content);
    }

    public generateTransferProtocol(employeeID: number): void {
        this._propertyId$
            .pipe(first())
            .subscribe(propertyId => {
                this._propertyService.generateTransferProtocolByPropertyAndEmployeeID(propertyId, employeeID)
                    .then(() => this.loadEmployees());
            });
    }

    public loadEmployees(): void {
        this._fetchListData();
    }

    public loadProperty(): void {
        this._fetchProperty$.next();
    }

    public loadPropertyFiles(): void {
        this._fetchPropertyFiles$.next();
    }

    public onSubmitEmployeeProperty(form: NgForm): void {
        if (!form.valid) {
            return;
        }

        this._propertyId$
            .pipe(first())
            .subscribe(propertyId => {
                this._propertyService.saveEmployeeProperty(form.value, this.selectedEmployeeProperty.employee_ID, propertyId)
                    .then(() => {
                        this.loadProperty();
                        this.loadEmployees();
                        this._modalService.dismissAll();
                    });
            });
    }

    public openEmployeePropertyDetailModal(content: any, employeeProperty: Employee): void {
        this.selectedEmployeeProperty = employeeProperty;

        this.employeePropertyFiles$ = of(employeeProperty.files);

        this.employeePropertyId = employeeProperty.employee_property_ID;

        this._modalService.open(content, {centered: true}).result
            .then(
                () => { },
                () => this.selectedEmployeeProperty = null
            );
    }

    public openPropertyEditModal(property: Property): void {
        const modalRef = this._modalService.open(CreateEditPropertyComponent, {centered: true});

        modalRef.componentInstance.property = property;

        modalRef.result
            .then(
                () => this.loadProperty(),
                () => { }
            );
    }

    public openThumbnailModal(content: any): void {
        this._modalService.open(content, {centered: true, size: 'lg'});
    }

    public openUploadModal(content: any): void {
        this._modalService.open(content, {centered: true});
    }

    public pageChanged(page: number): void {
        console.log('pageChanged()', page);

        this.page = page;
    }

    public removePropertyFromEmployee(employee: Employee, content: any): void {
        this.selectedEmployeeProperty = employee;

        this.employeePropertyFiles$ = of(employee.files);

        this.employeePropertyId = employee.employee_property_ID;

        this._modalService.open(content, {centered: true});
    }

    public revertProperty(): void {
        this._propertyId$
            .pipe(first())
            .subscribe(propertyId => {
                this._propertyService.revertProperty(propertyId)
                    .then(() => this.loadProperty());
            });
    }

    public searchChanged(search: string): void {
        this.search = search;
    }

    public sortChanged(sort: Sort<Employee>): void {
        this.sort = sort;
    }

    public trackByFn(index: number, employee: Employee): number {
        return employee.employee_ID;
    }

    public openCreateDocumentByTemplateModal(row: any): void {
        const modalRef = this._modalService.open(CreateDocumentByTemplateModalComponent, {centered: true});

        modalRef.componentInstance.employeeId = row.employee_ID;
        modalRef.componentInstance.entityType = 'PROPERTY';
        modalRef.componentInstance.entityId = row.employee_property_ID;

        modalRef.result
            .then(() => {
                this._fetchListData();
            }, () => { });
    }

    public openAssignPropertyModal(property: Property): void {
        const modalRef = this._modalService.open(AssignPropertyComponent, {centered: true});

        modalRef.componentInstance.property = property;

        modalRef.result
            .then(() => {
                this.loadProperty();
                this._fetchListData();
            }, () => { });

    }

    public openFilesModal(files: File[]): void {
        const modalRef = this._modalService.open(MultiFilesPreviewModalComponent, {centered: true, size: 'xl'});
        modalRef.componentInstance.files$ = of(files);
    }

    protected _fetchListData(): void {
        this._fetchEmployees$.next();
    }
}
