import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { StatsForDatetimeExport } from 'src/app/employee/interface/stats-for-datetime-export.interface';
import { Employee } from 'src/app/employee/models/Employee';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { CompanyGroup } from '../../../../company-group/state/company-group.model';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CompanyBranch } from '../../../../setting/models/CompanyBranch';
import { UserService } from '../../../../setting/services/user.service';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { Tag } from '../../../../tag/state/tag.model';
import { TagService } from '../../../../tag/state/tag.service';
import { EmployeeService } from '../../../services/employee.service';

interface DocumentStatus {
    step1: boolean;
    step2: boolean;
    step3: boolean;
    step4: boolean;
    step5: boolean;
}

@Component({
    selector: 'app-employee-attendance-document-detail',
    templateUrl: './employee-attendance-document-detail.component.html',
    styleUrls: ['./employee-attendance-document-detail.component.css']
})
export class EmployeeAttendanceDocumentDetailComponent extends FatherOfListComponent<Employee> implements OnInit {
    @ViewChild('filterFormOptions', {static: false})
    public filterFormOptions: TemplateRef<ElementRef>;

    public averageGrossSalary: number;
    public averageHourlyGrossSalary: number;
    public averageWorkedHours: number;
    public datetimeDocumentStatus$: Observable<DocumentStatus>;
    public grossSalarySummary: number;
    public hourlyGrossSalarySummary: number;
    public month: number;
    public selectedSummary: any;
    public stats$: Observable<StatsForDatetimeExport>;
    public companyGroups$: Observable<CompanyGroup[]>;
    public employeePositionTypes$: Observable<any>;
    public companyBranches$: Observable<CompanyBranch[]>;
    public tags$: Observable<Tag[]>;
    public hardSkillsTags$: Observable<Tag[]>;
    public softSkillsTags$: Observable<Tag[]>;
    public combined$: Observable<{
        stats: StatsForDatetimeExport,
        status: DocumentStatus
    }>;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public summaries = [];
    public workedHoursSummary: number;
    public year: number;
    public filterForm: UntypedFormGroup;
    public selectedEmployeeIDs: number[];
    public sort: Sort<Employee> = {
        column: 'fullname',
        direction: SORT_ASC
    };

    private _fetchEmployees$ = new ReplaySubject<void>(1);
    private _filterFormModalRef: NgbModalRef;

    public constructor(
        private _attendanceService: AttendanceService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _fb: UntypedFormBuilder,
        private _employeeService: EmployeeService,
        private _tagService: TagService,
        private _flatpickrLocale: FlatpickrLocaleService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public getCount(field: string) {
        return this.filterForm.get(field).value?.length;
    }

    public ngOnInit(): void {
        this.filterForm = this._fb.group({
            companyGroups: [null],
            employeePositionTypes: [null],
            positionCompanyBranches: [null],
            mainCompanyBranches: [null],
            tags: [null],
            hardSkillsTags: [null],
            softSkillsTags: [null],
        });

        this.year = parseInt(this._route.snapshot.queryParamMap.get('year'), 10);
        this.month = parseInt(this._route.snapshot.queryParamMap.get('month'), 10);

        this.companyGroups$ = this._employeeService.getAllCompanyGroups();
        this.employeePositionTypes$ = this._employeeService.getAllEmployeePositionTypes();
        this.companyBranches$ = this._employeeService.getAllCompanyBranches('minimal').pipe(shareReplay());

        this.tags$ = this._tagService.getAllTags('employee');
        this.hardSkillsTags$ = this._tagService.getAllTags('employee', 'hard_skill');
        this.softSkillsTags$ = this._tagService.getAllTags('employee', 'soft_skill');

        this._fetchStatsData();

        this._rows$ = this._fetchEmployees$
            .pipe(
                tap(() => this._loading$.next(true)),
                switchMap(() => this._attendanceService.getEmployeesForDatetimeExport(this.month, this.year, this.filterForm.value)
                    .pipe(tap(employees => {
                        this.selectedEmployeeIDs = employees.map(employee => employee.employee_ID);
                    }))),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();

        this.getSummaryBatchData();
    }

    public destroyBatch(): void {
        this._attendanceService.destroySummaryBatch(this.month, this.year)
            .then(
                result => {
                    if (result) {
                        this.getSummaryBatchData();
                        this._fetchListData();
                        this._fetchStatsData();
                    }
                },
                () => { }
            );
    }

    public downloadAllExport(employees: Employee[], type: string = 'default'): void {
        this._attendanceService.downloadAllDatetimeExports(this.month, this.year, this.selectedEmployeeIDs, type);
    }

    public downloadExport(employeeID: number): void {
        this._attendanceService.downloadDatetimeExportByEmpoyeeID(employeeID, this.month, this.year);
    }

    public getSummaryBatchData(): void {
        this._attendanceService.getNormalizedDatetimesDataForCheck(this.month, this.year)
            .pipe(
                map(summaries => {
                    this.grossSalarySummary = summaries.reduce((n, {gross_salary}) => n + gross_salary, 0);
                    this.averageGrossSalary = this.grossSalarySummary / summaries.length;

                    this.hourlyGrossSalarySummary = summaries.reduce((n, {hourly_gross_salary}) => n + hourly_gross_salary, 0);
                    this.averageHourlyGrossSalary = this.hourlyGrossSalarySummary / summaries.length;

                    this.workedHoursSummary = summaries.reduce((n, {properly_work_hours}) => n + properly_work_hours, 0);
                    this.averageWorkedHours = this.workedHoursSummary / summaries.length;

                    return summaries;
                })
            )
            .subscribe(summaries => this.summaries = summaries);
    }

    public openModal(content: any, employeeID: number): void {
        const summary = this.summaries.find(sum => sum.employee_ID === employeeID);

        if (summary) {
            this.selectedSummary = summary;
        }

        this._modalService.open(content, {centered: true}).result
            .then(
                () => this.selectedSummary = null,
                () => this.selectedSummary = null
            );
    }

    public searchChanged(search: string): void {
        this.search = search;
    }

    public sortChanged(sort: Sort<Employee>): void {
        this.sort = sort;
    }

    public trackByFn(index: number, employee: Employee): number {
        return employee.employee_ID;
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public onSubmitFilter(): void {
        this._filterFormModalRef?.close();

        this._fetchListData();
    }

    protected _fetchListData(): void {
        this._fetchEmployees$.next();
    }

    protected _fetchStatsData(): void {
        this.stats$ = this._attendanceService.getStatsForDatetimeExport(this.month, this.year);
        this.datetimeDocumentStatus$ = this._attendanceService.getDatetimesSummaryStatus(this.month, this.year);

        this.combined$ = combineLatest([
            this.stats$,
            this.datetimeDocumentStatus$
        ]).pipe(
            map(([stats, status]) => ({stats, status})));
    }
}
