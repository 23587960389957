import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Role } from 'src/app/setting/models/Role';
import { RolesService } from 'src/app/setting/services/roles.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-roles',
    templateUrl: './roles-list.component.html',
    styleUrls: ['./roles-list.component.css']
})
export class RolesListComponent extends FatherOfListComponent<Role> implements OnInit {
    @ViewChild('content', {static: true})
    public modalContent;

    public canCreate$: Observable<boolean>;

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.roleCreateForm.controls;
    }

    public roleCreateForm: UntypedFormGroup;

    public sort: Sort<Role> = {
        column: 'name',
        direction: SORT_ASC
    };

    public submitted = false;

    private _fetchRoles$ = new ReplaySubject<void>(1);

    public constructor(
        private _fb: UntypedFormBuilder,
        private _modalService: NgbModal,
        private _roleService: RolesService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
    ) {
        super(_ngZone, _changeDetectorRef, _authService, _userService);
    }

    public ngOnInit(): void {
        this.canCreate$ = this._authService.hasPermissionTo('role.create')
            .pipe(map(permission => permission.can));

        this._rows$ = this._fetchRoles$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._roleService.getRolesList(params)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this.roleCreateForm = this._fb.group({
            name: ['', Validators.required]
        });

        this._fetchListData();
    }

    public deleteRole(roleID: number): void {
        this._roleService.deleteRole(roleID)
            .then(() => this._fetchListData());
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.roleCreateForm.valid) {
            return;
        }

        this._roleService.saveRole(this.roleCreateForm.value)
            .then(() => {
                this._fetchListData();

                this._modalService.dismissAll();
            });

        this.roleCreateForm.patchValue(({name: ''}));
    }

    public openEditModal(): void {
        this._modalService.open(this.modalContent, {centered: true});
    }

    public trackByFn(index: number, role: Role): number {
        return role.role_ID;
    }

    protected _fetchListData(): void {
        this._fetchRoles$.next();
    }
}
